.title{
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    color: #4F4949;
}
.group-container{
    padding-top: 15px;
}
.coverImgNew {
    display: inline-flex;
    flex-direction: row-reverse;
    background: #BFBFBF;
    border-radius: 20px;
    min-height: 144px;
    height: 16vh;
}
.bookCoverImg1 {
    position: relative;
    border: none;
    overflow: hidden;
    width: 100px;
    height: 124px;
    top: 17px;
    left: -15px;
}
.bookCoverImg2 {
    position: relative;
    border: none;
    overflow: hidden;
    width: 100px;
    height: 124px;
    top: 11px;
    left: -5px;
}
.bookCoverImg3 {
    position: relative;
    border: none;
    overflow: hidden;
    width: 100px;
    height: 124px;
    top: 5px;
    left: 7px;
}
.bookCoverImgLast:not(:last-child) {
    margin-left: -60px;
}
.bookCoverImgLast img,
.bookCoverImgLast img,
.bookCoverImgLast img {
    width: 100%;
    height: 100%;
    display: block;
}
.pl-7 {
    padding-left: 7px;
}
.CustomizeButtonSpotlight{
    border-radius: 23px;
    width: 165px;
    border-color: #090808;
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: 600;
    font-size:14px;
    height:32px
}
.CustomizeButtonSpotlight.ant-btn{
    &:hover {
        border-color: #090808;
        color: #FFFFFF;
      }
}
.CustomizeButtonPadding{
    max-height: 88vh;
}
.FeatureListNewTag{
    font-family: IBM Plex Sans;
    font-style: normal;
    border-radius: 5px;
    border:1px solid black !important;
    border-color: #1c1c1c;
    color: #1c1c1c;
    font-weight: 500;   
    font-size: 11px;   
    margin-left: 12px;
    padding: 1px 3px 0px 2px;
}
.category-column{
    display: flex;
    align-items: center;
}