.advanced-search-side-bar {
  .advance-search-side-form{
    overflow-y: auto;
    overflow-x: hidden;
    // height: 100%;
  }
  .scrollContent {
    padding-left: 10px;
    overflow-y: scroll; 
    overflow-x: hidden;
    padding-right: 2px;
    // height: 100%;

  }
  .heading-para {
    font-size: large;
    font-weight: 900;
    margin: 10px 10px 10px 10px;
  }
  .ant-card-body {
    padding: 0px;
  }
  .form-container {
    background: #f3f3f3;
    padding: 0px 6px 0px 7px;
    .provider-div {
      display: flex;
      flex-direction: column;
      row-gap: 5px;
    }

    .searchForm-card {
      border-radius: 4px;
      margin-top: 0px;
      padding: 0px 10px;

      .filters-div {
        label {
          width: 100%;
        }
      }
    }
    .row-spacing {
      display: flex;
      flex-direction: column;
      row-gap: 10px;
    }
  }

  .advance-search-header {
    padding: 5px 0px 5px 0px;
    background: #f3f3f3;
    display: flex;
    row-gap: 10px !important;
    margin: auto;
    .action-button {
      button{
        height: 30px;
        box-shadow: none;
      }
    }
    .clear-button {
      max-width: 230px !important;
      display: flex;
      justify-content: center;
      .primary{
        background: #ffffff !important;
        height: 30px;
        border: 1px solid #1C1C1C;
        color: #000000 !important;
        width: 93.05%;
        float: right;
        box-shadow: none;
      }
    }
    .primarySearch {
      width: 230px !important;
      display: flex;
      justify-content: center;
    }
  }
  .linkButton {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }
  .ant-picker-panel-container{
    position: fixed;
    top: 0;
    left: 10px;
  }
}
.heading-button-container{
  margin: 0px 15px 10px 7px;
}
.filterC{
  height:10vh;
  background-color:transparent;
}

@media screen and (min-resolution: 48dpi) and (max-resolution: 95dpi) {
  .sideForm {
    height: 92vh;
    max-height: 90vh;
    overflow-y: scroll;
    overflow-x: hidden;
  }
}

/* For 100% Scaling (Typically 96 DPI)*******  */
@media screen and (min-resolution: 96dpi) and (max-resolution: 109dpi) {
  .sideForm {
    height: 100%;
    max-height:90vh;
    overflow-y: scroll;
    overflow-x: hidden;
  }
}



/* For 125% Scaling (Typically around 120-125 DPI)***** */
@media screen and (min-resolution: 120dpi) and (max-resolution: 143dpi) {
  .sideForm {
    height: 100%;
    max-height: 89vh;
    overflow-y: scroll;
    overflow-x: hidden;
  }
}

/* For 150% Scaling (Typically around 144-150 DPI)***** */
@media screen and (min-resolution: 144dpi) and (max-resolution: 167dpi) {
  .sideForm {
    height: 100%;
    max-height: 84vh;
    overflow-y: scroll;
    overflow-x: hidden;
  }
}

/* For 175% Scaling (Typically around 168-175 DPI)******* */ 
@media screen and (min-resolution: 168dpi) and (max-resolution: 180dpi) {
  .sideForm {
    height:100%;
    max-height: 80vh;
    overflow-y: scroll;
    overflow-x: hidden;
  }
}

/* For 200% Scaling (Typically 192 DPI and above) */
@media screen and (min-resolution: 192dpi) {
  .sideForm {
    height: 100%;
    max-height: 86vh;
    overflow-y: scroll;
    overflow-x: hidden;
  }
}
@media screen and (min-resolution: 180dpi) {
  .sideForm {
    height: 100%;
    max-height: 78vh;
    overflow-y: scroll;
    overflow-x: hidden;
  }
}


