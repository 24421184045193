.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.timeLogOutModal .ant-modal-content .ant-modal-body {
  padding-bottom: 5px !important;
    justify-content: center !important;
    padding-top: 30px !important;
    display: flex !important;
}
.timeLogOutModal .ant-modal-content .ant-modal-footer {
  text-align: center !important;
  background: transparent;
  border-radius: 0 0 2px 2px;
  border-top: 0px;
  padding-bottom: 30px !important;
}
.logoutModal{
  top: 25%;
}
.modalMsg{
  font-weight: normal;
    font-size: 20px;
    line-height: 28px;
    align-items: center;
    text-align: center;
    color: #000000;
}
.decline-button{
  width: 140px;
  height: 40px;
  background: #EE0000;
  border-radius: 50px;
  box-shadow: 0px 45px 80px rgb(238 0 0 / 14%), 0px 22.522px 40.0391px rgb(238 0 0 / 11%), 0px 13.5662px 24.1177px rgb(238 0 0 / 9%), 0px 8.69392px 15.4559px rgb(238 0 0 / 8%), 0px 5.6347px 10.0172px rgb(238 0 0 / 7%), 0px 3.54698px 6.30574px rgb(238 0 0 / 6%), 0px 2.03796px 3.62304px rgb(238 0 0 / 5%), 0px 0.896959px 1.59459px rgb(238 0 0 / 3%);
  border: 2px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
}

.decline-button:hover {
  background: #ff2424;
  border-radius: 50px;
  box-shadow: 0px 45px 80px rgb(238 0 0 / 14%), 0px 22.522px 40.0391px rgb(238 0 0 / 11%), 0px 13.5662px 24.1177px rgb(238 0 0 / 9%), 0px 8.69392px 15.4559px rgb(238 0 0 / 8%), 0px 5.6347px 10.0172px rgb(238 0 0 / 7%), 0px 3.54698px 6.30574px rgb(238 0 0 / 6%), 0px 2.03796px 3.62304px rgb(238 0 0 / 5%), 0px 0.896959px 1.59459px rgb(238 0 0 / 3%);
  border: 2px;
  color: #ffffff;
  cursor: pointer;
}
.yes-button{
  width: 140px;
  height: 40px;
  border: 1px solid #1c1c1c;
  background: transparent;
  box-sizing: border-box;
  border-radius: 60px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  cursor: pointer;
}
.yes-button:hover{
  border: 1px solid #1c1c1c;
  background: transparent;
  color: #1c1c1c;
}