.subRowWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.errorValidation{
    margin-left: 22px;
    color: red;
}
.categoryList{
    padding: 25px 10px 0;
    .categoryList_text{
        color: rgba(0, 0, 0, 0.85);
        font-size: 14px;
    }
    .categoryList_button{
        font-family: IBM Plex Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        color: #1c1c1c;
        text-decoration-line: underline;
        background: transparent !important;
        border: 0px !important;
        box-shadow: none;
        border-radius: 0px !important;
        text-align: right;
        height: 20px !important;
        margin-top: 0px;
        cursor: pointer;
    }
.categoryList_button_red{
        font-family: IBM Plex Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        color: #EE0000;
        text-decoration-line: underline;
        background: transparent !important;
        border: 0px !important;
        box-shadow: none;
        border-radius: 0px !important;
        text-align: right;
        height: 20px !important;
        margin-top: 0px;
        cursor: pointer;
    }
}

.libraryBtn button{
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #1c1c1c;
    text-decoration-line: underline;
    background: transparent !important;
    border: 0px !important;
    box-shadow: none;
    border-radius: 0px !important;
    text-align: right;
    height: 20px !important;
    margin-top: 0px;
    &:hover {
        background: transparent !important;
        box-shadow: none !important;
        border: none !important;
        border-radius: none;
    }
}
.libraryScroll {
    max-height: 70vh;
}
.uploadDragger {
    max-height: 200px;
}
.libraryStuffHeader {
    justify-content: space-between;
    margin-bottom: 15px;
}
.libraryCol {
    margin-right: 10px;
}
.libraryStuffCol {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.lib-btn-wrapper {
    margin-right: 10px;
}
.lib-btn-wrapper button {
    height: 35px;
}
.mt-5 {
    margin-top: 5px;
}
.drawer-wrapper {
    height: 100%;
    padding-top: 20px;
}
.library-save button {
    margin-bottom: 10px;
    height: 35px;
    margin-top: 15px;
}

.upload .ant-upload-select-text {
    width: 100%;
    button {
        margin-top: 5px;
        height: 35px;
    }
}

.img-preview {
    width: 50px;
    height: 50px;
}
.col-wrapper {
    opacity: 0.1;
}
.categoryCarrat {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-top: 16px;
    img {
        width: 18px;
        height: 18px;
        cursor: pointer;

    }
}

.rotateimg90 {
    -webkit-transform: rotate(270deg);
    -moz-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    -o-transform: rotate(270deg);
    transform: rotate(270deg);
}

.patronConfigCol {
    display: flex;
    justify-content: flex-end;
    align-items:flex-end;
}

.globalSetingsOptions {
    .ebook {
        display: inline;
    }
    .audiobook {
        display: inline;
    }
}

.drawerWrapper {
    height: 100%;
    flex-direction: column;
    display: flex;
    justify-content: space-between;
}

.drawerCheckbox {
    display: flex;
    justify-content: flex-end;
}

.drawerUserInfo {
    margin-right: 24px;
    padding: 12px;
    border: 1px solid #DEDEDE;
    border-radius: 4px;
}

.drawerrole {
    margin-top: 24px;
}

.drawerbutton {
    margin-right: 24px;
    button {
        height: 35px;
    }
    button:first-of-type{
        margin-bottom: 15px;
    }
}

.drawerAdd{
    padding-right: 24px;
    button {
        height: 35px;
    }
}
.libraryDrawerError {
    color: #e00;
    font-weight: 400;
    font-family: IBM Plex Sans!important;
    font-style: normal;
    font-size: .75rem!important;
    line-height: 20px!important;
    margin-left: 10px;
    margin-bottom: 5px;
}
.libraryRolesError {
    margin: 0px;
    margin-top: 5px;
}
.global-setting-title{
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    padding-bottom: 20px;
    padding-top: 24px;
}
.global-setting-label{
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    padding-bottom: 12px;
    padding-top: 24px;

}
.Audio {
    margin-left: 12px;
    display: inline;
}
.Ebook{
    display: inline;
}
.global-setting-checkbox{
    padding-bottom: 14px;

}
.MaxLoanedDocuments{
    padding-top: 24px !important;
}
.margin-adjust{
    margin-top: 21px;
}
.drawer-padding-adjusted{
    padding-top: 25px;
}
.drawer-title-bolding{
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
}
.library-labeling{
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
}

.library-sub-heading {
    font-family: "IBM Plex Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 10px;
}
.libraryActive {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #52E78D;
}

.libraryInActive {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #EE0000;
}
.downloadLink {
    color: #000000;
    cursor: pointer;
    &:hover{
        color: #000000;
    }
}
.display-search-icon span span button.ant-btn-primary {
    border-top-right-radius: 16px !important;
    border-bottom-right-radius: 16px !important;
    border-color: rgba(115, 115, 115, 0.4);
    border-left: none;
    background: none;
    color: black
}
.display-search-icon input{
    &:hover{
        border-color: rgba(115, 115, 115, 0.4) !important;
    } 
    &:focus{
        border-color: rgba(115, 115, 115, 0.4) !important;
        box-shadow: none;
        border-right: none;
    }
}

.downloadBtn{    
    width: 100%;
    height: 35px;
    border: 1px solid #1c1c1c;
    background: transparent;
    box-sizing: border-box;
    border-radius: 60px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    cursor: pointer;
}

.downloaddiv{
    display: grid;
    text-align: end;
    margin-right: 10px;
}
.patron-button-wrapper {
    padding-bottom: 10px;
    justify-content: flex-end;
    button {
        height: 35px;
    }
}
.libraryBtn {
    display: flex;
}
.patronCancelBtn {
    margin-right: 20px;
}
.patronScroll {
    max-height: 71vh;
}
.LibraryStaffPopover div.ant-popover{
    width: 13vw;
}

.drawer-title{
    font-family: "IBM Plex Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
}
.patronInput {
    margin-left: 5px;
}
.sub-drawer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    margin-right: 12px;
    padding-right: 12px;
    padding-left: 12px;
    border: 1px solid #dedede;
}
.image-row {
    background-color: #f3f3f3;
    padding-top: 12px;
    padding-bottom: 12px;
}

.libraryUploadImage {
    padding: 12px;
    padding-left: 20px;
    max-width: 140px;
    max-height: 124px;
    width: 100%;
    height: 100%;
}
.libBtnIconImg{
    margin-right: 10px;
    padding-bottom: 2px;
}
.upload-text {
    font-family: 'IBM Plex Sans';
font-style: normal;
font-weight: 700;
font-size: 14px;
line-height: 18px;
display: flex;
align-items: center;
color: #898989;
padding-bottom: 10px;
padding-top:10px;
}
.libraryRemoveBtn button {
    color: #EE0000;
    padding-right: 30px;
}
.patron-button-wrapper {
    padding-bottom: 10px;
    justify-content: flex-end;
}
.libraryBtn {
    display: flex;
}
.libDrop {
    .ant-select-dropdown {
        left: 0px !important;
    }
}
.input-when-error{
  margin-bottom: 2px;
  background: none !important;
}
.LibraryStaffPopover{
    margin-bottom: 8px;
}
.drawerCheckbox label span span{
    border-color:  rgba(115, 115, 115, 0.4);  
}
.patronForm {
    .ant-form-item-explain-error {
        padding-left: 13px;
    }
}
.patronHideCategory {
    padding-bottom: 10vh;
}
.patronDisableInput {
    cursor: not-allowed !important;
}
.patronFooter {
    padding-bottom: 10vh;
    padding-right: 4vw;
}
.disabled{
    border: 1px solid rgba(115, 115, 115, 0.4)!important;
    &:disabled{
        cursor: not-allowed !important;
    }
}
.libraryDrawer {
    .ant-spin-dot {
        display: none;
    }
}

.drawerRadioDiv{
    margin-bottom:24px ;
    }

.libBtnIcon{
    width: 128px;
    background: #1C1C1C;
    color: #FFFFFF;
    height: 34px;
    border-radius: 17px;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    border: 1px solid #1C1C1C;
    margin-bottom: 23px;
    cursor: pointer;
}
.libraryScroll :nth-child(4) .libraryBtn {
    display: none;
}
.libDrawerImg div:first-of-type {
    width: fit-content;
}