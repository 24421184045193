.newSpotlight {
  display: flex;
  height: calc(100vh - 74px);
  overflow: auto;
  .ellipsis {
    position: relative;
    margin-top: 3em;
  }
  .switch-wrapper {
    button {
      background-color: rgb(104 97 98 / 85%);
    }
  }
  .verticalDivider {
    height: 93%;
    border-left: 1px solid rgb(0 0 0 / 9%);
  }
  .ant-divider{
    border-top: 1px solid rgb(0 0 0 / 9%);
  }
  .borderRight {
    border-right: 1px solid rgba(0, 0, 0, 0.06);
  }
  .spotlight-divider {
    margin: 0px;
  }
  .paragraphBox {
    padding: 10px 5px 0px 10px;
    @media screen and (max-width: 1200px) {
      height: 100%;
    }
    .headerPart {
      margin-bottom: 10px;
      .spotlightHead {
        font-weight: 700;
        font-size: 1.2rem;
        margin-right: 15px;
        @media screen and (max-width: 1360px) {
          font-size: 1rem;
        }
        @media screen and (max-width: 1100px) {
          font-size: 0.7rem;
        }
      }
      .spotlightSubHead {
        font-weight: 500;
        font-size: 1.2rem;
        @media screen and (max-width: 1360px) {
          font-size: 1rem;
        }
        @media screen and (max-width: 1100px) {
          font-size: 0.7rem;
        }
        @media screen and (min-width: 2000px) {
          font-size: 1.4rem;
        }
      }
    }
    .spotlightPara {
      text-align: justify;
      margin-bottom: 0em;
      @media screen and (max-width: 900px) {
        font-size: 0.6rem;
      }
      @media screen and (min-width: 900px) and (max-width: 1100px) {
        font-size: 0.7rem;
      }
      @media screen and (min-width: 1100px) and (max-width: 1360px) {
        font-size: 0.9rem;
      }
      @media screen and (min-width: 1360px) and (max-width: 1600px) {
        font-size: 1rem;
      }
      @media screen and (min-width: 1600px) and (max-width: 2000px) {
        font-size: 1.1rem;
      }
      @media screen and (min-width: 2000px) {
        font-size: 1.3rem;
      }
    }
  }
  .sectionBdevider {
    margin: 5px 0;
  }
  .feature-heading {
    font-weight: 950;
    @media screen and (max-width: 770px) {
      font-size: 1.2rem;
    }
  }
  .paragraphBoxSecond {
    padding: 5px 10px;
    @media screen and (max-width: 1200px) {
      height: 100%;
    }
    .headerPart {
      margin-bottom: 10px;
      .spotlightHead {
        font-weight: 600;
        font-size: 1.5vw;
        margin-right: 15px;
        @media screen and (max-width: 820px) {
          font-size: 1.8vw;
        }
        @media screen and (min-width: 1490px) {
          font-size: 1.3vw;
        }
      }
      .spotlightSubHead {
        font-weight: 500;
        font-size: 1.2rem;
        @media screen and (max-width: 1360px) {
          font-size: 1rem;
        }
        @media screen and (max-width: 1100px) {
          font-size: 0.7rem;
        }
        @media screen and (min-width: 2000px) {
          font-size: 1.4rem;
        }
      }
    }
    .spotlightPara {
      text-align: justify;
      @media screen and (max-width: 900px) {
        font-size: 0.6rem;
      }
      @media screen and (max-width: 1100px) and (min-width: 900px) {
        font-size: 0.7rem;
      }
      @media screen and (min-width: 1100px) and (max-width: 1360px) {
        font-size: 0.9rem;
      }
      @media screen and (min-width: 1360px) and (max-width: 1600px) {
        font-size: 1rem;
      }
      @media screen and (min-width: 1600px) and (max-width: 2000px) {
        font-size: 1.1rem;
      }
      @media screen and (min-width: 2000px) {
        font-size: 1.3rem;
      }
    }
  }
  .newRowC {
    .card-books-container {
      padding-top: 15px;
      padding-bottom: 10px;
      padding-left: 20px;

      .left-arrow-container {
        width: 20px;
      }
      .card-books {
        img {
          padding: 0 3px;
          width: 120px;
          height: 164px;
          margin-bottom: 6px;
          margin-left: 3px;
        }
        img:nth-child(1) {
          padding-left: 0 !important;
        }
      }
      .shelve-img-transbox {
        position: absolute;
        opacity: 0.7;
        bottom: 44px;
        background-color: black;
        top: 119px;
        width: 94%;
        height: 45px;
        margin-left: 3px;
      }
      P .shelve-img-price {
        font-size: 13px;
        font-weight: 900;
        color: #fafafa;
        padding-left: 16px;
      }
      .text-count {
        font-family: IBM Plex Sans;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 20px;
        color: #4f4949;
      }
    }
    .shelves-img-container {
      position: relative;
      display: inline-flex;
      cursor: pointer;
    }
    .left_Arrow {
      position: absolute;
      top: 35%;
      width: 20px;
      left: -21px;
      font-size: 35px;
      background: #00000008;
      cursor: pointer;
      text-align: center;
    }
    .leftSideSLider {
      height: inherit;
      display: grid;
      top: 50%;
      left: -2%;
      z-index: 1;
      position: absolute;
    }
    .rightSideSLider {
      height: inherit;
      display: grid;
    }
    .Arrow-Slider-container {
      background-color: black;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      font-size: 13px;
      font-weight: 800;
      display: flex;
      align-items: center;
      justify-content: center;
      .arrow-right {
        color: white;
      }
      .ant-col {
        border: none !important;
        text-align: center;
        .view-all-link {
          padding-left: 0;
          white-space: nowrap;
        }
      }
    }
  }
  .featureBox {
    padding: 10px;
    .ant-col {
      border: none;
    }
    ul {
      list-style-type: none;
      padding-left: 0;
      overflow: auto;

      @media screen and (min-width: 1201px) {
        height: 100%;
        max-height: 50vh;
        min-height: 10vh;
      }
      @media screen and (max-width: 1200px) {
        height: 100%;
        max-height: 40vh;
        min-height: 10vh;
      }
      .selected {
        opacity: 1;
        color: red;
      }
    }
    .featureListBox {
      .featureList-divider {
        margin: 10px 0px;
      }
      li {
        cursor: pointer;
        @media screen and (max-width: 750px) {
          font-size: 0.9em;
        }
      }
    }
    .feturBoxFooter {
      position: relative;
      width: 100%;
      bottom: 0;
      padding: 10px;
      left: 0;
      .featureBocCliclList {
        cursor: pointer;
      }
    }
  }
  .profile {
    padding: 10px 0px 10px 5px;
    display: flex;
    position: relative;
    @media screen and (max-width: 900px) {
      flex-direction: column;
    }
    .profileImage {
      display: inline-block;
      max-width: 6vw;
      height: 6vw;
      @media screen and (max-width: 1300px) {
        width: 100%;
      }
      @media screen and (max-width: 1300px) {
        width: 40%;
      }
      img {
        width: 5vw;
        max-height: 250px;
        height: 100%;
        object-fit: cover;
        background: #1d20220f;
      }
    }
    .profilePara {
      overflow: hidden;
      p {
        word-wrap: break-word;
        margin-bottom: 0;
        .pEmailAdress{
          font-size: 0.8rem;
        }
      }
    }
    .author-name {
      font-size: 1rem;
      margin-bottom: 5px;
      @media screen and (max-width: 1300px) {
        font-size: 0.9rem;
      }
      @media screen and (max-width: 1100px) {
        font-size: 0.7rem;
      }
    }
    p {
      float: right;
      text-align: left;
      margin-left: 2px;
      padding-left: 10px;
      font-size: 0.9rem;
      font-weight: 600;
      color: grey;
      width: 100%;
      @media screen and (max-width: 1300px) {
        width: 100%;
        font-size: 0.8rem;
      }
      @media screen and (max-width: 1100px) {
        font-size: 0.6rem;
      }
      @media screen and (max-width: 900px) {
        padding-left: 0px;
      }
      display: inline-block;
      top: 10px;
    }
    .emailAdress{
      height: 34%;
      position: absolute;
      bottom: 0;
      right: 5%;
      p{
        padding: 0 !important;
      }
      @media screen and (max-width: 1600px) {
        height: 20%;
        position: absolute;
        bottom: 0;
        right: -8%;
      }
      @media screen and (max-height: 700px) {
        height: 20%;
      }
      @media screen and (max-width: 1381px) {
        height: 16%;
      }
      @media screen and (max-width: 1221px) {
        height: 13%;
      }
      @media screen and (max-width: 1100px) {
        height: 20%;
      }
      @media screen and (max-width: 940px) {
        height: 15%;
      }
    }
  }
  .addToCardComponent {
    padding: 10px;
    .switch-wrapper {
      height: 18px;
      padding-bottom: 20px;
      position: relative;
      margin-top: 5px;
      min-width: 38px;
      label {
        padding-left: 40px;
        padding-right: 5px;
      }
    }
  }
  .vertical-line {
    border: 1px solid red;
  }
  .sectionB {
    .middle-bottom {
      margin-right: 10px;
      height: 35vh;
      .featured-list-details {
        display: flex;
        flex-direction: column;
        @media screen and (max-width: 750px) {
          font-size: 0.9em;
        }
      }
      .spotlight-add-to-cart-button {
        width: 100%;
        height: 15vh;
        position: relative;
      }
      .align-button {
        position: relative;
        margin-bottom: 12px;
        left: 5px !important;
        @media screen and (min-width: 750px) {
          top: 12%;
        }
        @media screen and (min-width: 1000px) {
          top: 20%;
        }
        @media screen and (min-width: 1225px) {
          top: 34%;
        }
        @media screen and (min-width: 1400px) {
          top: 40%;
        }
        @media screen and (min-width: 1500px) {
          top: 40%;
        }
        @media screen and (min-width: 1750px) {
          top: 45%;
        }
        @media screen and (min-width: 2000px) {
          top: 45%;
        }
      }
    }
  }
}
