.searchContainer {
  max-height: 90vh;
  padding-bottom: 80px;
  padding-right: 24px;
}
.ant-select-selection-item{
  .ant-select-selection-item-content{
    span{
      display: none !important;
    }
  }
}
.unselectAllText {
  font-size: 14px !important;
  font-weight: 500px !important;
}
.contentText {
  font-size: 14px !important;
  font-weight: 500 !important;
}
.ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-inner {
  background-color: #f5f5f5;
  border-color: #d9d9d9 !important;
  display: none !important;
}
.showOnlyCheckedBtn{
  float: right;
  margin-right: 25px;
}
.multiSelectDropDown{
  padding-left: 0 !important;
  .ant-select-selector{
    border: none !important;
    padding-right: 0 !important;
    padding-left: 0 !important;
    border-radius: 50px !important;
  }
  .ant-select-selection-placeholder{
    padding-left: 8px;
  }
  .ant-select-clear {
    position: absolute;
    top: 50%;
    right: 30px !important;
    z-index: 1;
    display: inline-block;
    width: 12px;
    height: 12px;
    margin-top: -6px;
    color: rgba(0, 0, 0, 0.25);
    font-size: 12px;
    font-style: normal;
    line-height: 1;
    text-align: center;
    text-transform: none;
    background: #fff;
    cursor: pointer;
    opacity: 0;
    transition: color 0.3s ease, opacity 0.15s ease;
    text-rendering: auto;
}
}
.form-container {
  background: #f3f3f3;
  padding-left: 21px;
  padding-right: 13px;
  padding-top: 18px;
  padding-bottom: 27px;
  .ant-checkbox-inner {
    padding-left: 2px;
    height: 30px !important;
    width: 30px !important;
    border-radius: 5px;
  }
  .ant-checkbox-wrapper {
    align-items: center;
    font-size: 14px;
    line-height: 18px;
    color: #333333;
    &:hover{
      .ant-checkbox-inner{
        border: 1px solid rgba(115, 115, 115, 0.4) !important;
      }
    }
    .ant-checkbox-inner::after {
      position: absolute;
      display: table;
      border: 1px solid #ffff;
      border-top: 0;
      border-left: 0;
      transform: rotate(42deg) scale(2) translate(-16%, -50%);
      opacity: 1;
      transition: all 0.1s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
      content: ' ';
    }
  }
  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0px !important;
  }
  .ant-select-item-option-state {
    display: none !important;
  }
}
.searchForm-card {
  border-radius: 4px;
  margin-top: 20px;
}
.card-title {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #1c1c1c;
  margin-bottom: 12px;
}
.card-input {
  margin-bottom: 12px;
  background: none !important;
}
.card-input-with-search{
  margin-bottom: 12px;
  background-image: url("../../../../../assets/icons/search.svg");
  background-position: 95% 8px;
  background-repeat: no-repeat;
  border-radius: 20px;
  padding: 5px 35px 5px 20px;
  overflow-y: auto;
  max-height: 150px !important;
  &:focus {
    border-radius: 20px;
    min-height: 150px;
    background-position: 95% 8px;
  }
}
.card-dropdown {
  border: 1px solid rgba(115, 115, 115, 0.4);
  border-radius: 100px;
  margin-bottom: 12px;
  padding-left: 8px;
}
.cards-checkbox {
  margin-right: 29.5px;
}
.pricing-col {
  padding-right: 12px;
}
.with-search {
  .ant-select-arrow {
    height: 16px;
    width: 16px;
  }
}

.primarySearch {
  button {
    width: 93.05% !important;
    float: right;
  }
}
.search-footer {
  background: #ffffff;
  padding-top: 25px;
  padding-bottom: 25px;
  .action-button {
    button{
      height: 40px;
    }
  }
  .clear-button{
    .primary{
      background: #ffffff !important;
      height: 40px;
      border: 1px solid #1C1C1C;
      color: #000000 !important;
      width: 93.05%;
      float: right;
    }
  }
}
.search-top {
  padding-right: 20px;
  background: #ffffff;
  padding-top: 10px;
  padding-bottom: 10px;
  .action-button {
    button{
      height: 40px;
    }
  }
  .clear-button{
    .primary{
      background: #ffffff !important;
      height: 40px;
      border: 1px solid #1C1C1C;
      color: #000000 !important;
      width: 93.05%;
      float: right;
    }
  }
}

.linkButton {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  button {
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    text-decoration-line: underline;
    color: #333333;
    background: transparent !important;
    border: 0px !important;
    box-shadow: none;
    border-radius: 0px !important;
    text-align: right;
    width: 90px;
    height: 20px !important;
    &:hover {
      background: transparent !important;
      box-shadow: none !important;
      border-radius: 0px !important;
      border: 0px !important;
    }
  }
}
.card-datepicker{
  border: 1px solid rgba(115, 115, 115, 0.4);
  border-radius: 100px;
  margin-bottom: 12px;
  padding-left: 20px;
  height: 34px;
  width: 100%;
}
.search-modal-title{
  justify-content: center;
        padding-bottom: 40px;
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 28px;
        display: flex;
        align-items: center;
        color: #1C1C1C;
}
.searchSave{
  height: 40px;
}

.cancel-search{
  padding-top:12px;
  border: none;
  background: transparent;
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-decoration-line: underline;
  color: #000000;
  cursor: pointer;
}

.treeSelectDropdown{
  .ant-select-selector{
    border: 1px solid rgba(115, 115, 115, 0.4) !important;
    border-radius: 100px !important;
  }
  .ant-select-clear{
      margin-right: 22px;
  }
  .ant-select-selection-placeholder{
    left: 15px !important
  }
}
.ant-select-tree-treenode-switcher-open{
  .ant-select-tree-checkbox{
    order:1 !important;
}
  .ant-select-tree-switcher {
    order: 2 !important;
}
 .ant-select-tree-node-content-wrapper{
  order: 3 !important
}
.ant-select-tree-switcher-noop{
  display: none !important;
}

}
.ant-select-tree-treenode-switcher-close{
  .ant-select-tree-checkbox{
    order:1 !important;
  }
  .ant-select-tree-switcher {
    order: 2 !important;
}
 .ant-select-tree-node-content-wrapper{
  order: 3 !important
}
.ant-select-tree-switcher-noop{
  display: none !important;
}
}
.ant-select-tree-checkbox-checked{
  .ant-select-tree-checkbox-inner {
    background-color: #161515 !important;
    border-color: #161515 !important;
}
}
.ant-select-tree-switcher{
  z-index: 9!important;
}
.ant-select-tree-checkbox-indeterminate .ant-select-tree-checkbox-inner::after {
  background-color: #161515 !important;
}
.ant-select-multiple .ant-select-selection-item{
  background: #8bf0b4 !important;
  border: 1px solid #8bf0b4 !important;
  border-radius: 10px !important;
}
.ant-select-multiple .ant-select-selection-item-content{
  color: rgb(17, 133, 63) !important;
  font-weight: 600 !important;
  font-size: 12px !important;
}
.showError {
  color: #EE0000 !important;
    font-family: IBM Plex Sans !important;
    font-style: normal;
    font-weight: normal;
    font-size: 0.75rem !important;
    line-height: 20px !important;
}
.errorShow input {
  border: 1px solid #EE0000 !important;
  box-shadow: none !important;
}
.narator-input {
  background: #f3f3f3 !important;
  &:hover{
    border: none;
  }
}
.Search_Save_btn{
  font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    text-decoration-line: underline;
    color: #333;
    background: transparent !important;
    border: 0px !important;
    box-shadow: none;
    border-radius: 0px !important;
    text-align: right;
    width: 90px;
    height: 20px !important;

    &:hover {
      cursor: pointer;
    }
}
.ant-input-disabled{
  cursor: not-allowed !important;
  border: 1px solid #f0f0f0 !important;
  border-radius: 100px !important;
}