.book-list{
font-family: IBM Plex Sans;
    .ant-divider{
        margin-top: 7px;
        margin-bottom: 12px;
    }
    .pagination-tag{
        margin: auto;
        .pageStyle {
            padding-top: 15px;
          }
    }
}
/* Ensure the list container takes up the full viewport without causing overflow */
.list-container {
    height: 100vh;
    width: 100vw;
    overflow: hidden;
  }
  
  .List {
    outline: none; /* Optional: Removes the default outline */
  }
  
.bookCoverCheckBox{
    .ant-checkbox-wrapper {
        flex-flow: wrap-reverse;
        justify-content: center;
        position: absolute;
        top: 20% !important;
        left: 0% !important;
        width: 100%;
        height: 100%;
        z-index: 10;
        zoom: 1.5;
      }
}
.exportListCsv{
    display: flex;
    vertical-align: middle;
    justify-content: center;
    height: 100%;
    padding-top: 6px;
    padding-bottom: 12px;
    padding-left: 5px;
}
.CategoryName{
font-style: normal;
font-weight: bold;
font-size: 24px;
line-height: 28px;
color: #4F4949;
}
.title-count{
    font-family: IBM Plex Sans;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 18px;
color: #4F4949;
}
.filters-contatiner{
    padding-left: 4px;
}
.filters{
    background: #DEDEDE;
    border-radius: 100px;
    height:32px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px !important;
    line-height: 16px !important;
    display: flex;
    align-items: center;
    text-align: center;
    color: #000000;
}

.select-all{
    button{
    height: 35px;
    }
    padding-right: 40px;
}
.book-details-container{
    padding-top: 24px;
}
.books-divider{
    margin-bottom: 0px !important;
    margin-top: 14px;
}
.hzDivider {
    border-top: 1px solid #DEDEDE !important;
}
.verticalDivider {
    height: 180px;
    top: 0;
    margin-top: 5px !important;
    border-left: 1px solid #DEDEDE !important;
}
.books{
    padding-bottom: 5vh;
    padding-right: 12px;
    
}
.book-title{
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    cursor: pointer;
    &:hover{
        text-decoration: underline;
    }
}
.book-cover{
    position: relative;
    width: 100%;
    height: 200px;
    max-width: 150px;
    cursor: pointer;
    .cover{
        width: 100%;
        height: 100%;
    }
    .book-label{
        height: 24px;
        width:24px;
        position: absolute;
        right: 8px;
        top: 8px;
    }
}
.book-price{
    font-weight: 600;
font-size: 12px;
line-height: 16px;
color: #FF0000;
text-decoration:line-through;
}
.book-price-final{
    font-weight: bold;
font-size: 16px;
line-height: 24px;
color: #1C1C1C;
}
.book-genre{
    border: 1px solid rgba(222, 222, 222, 1);
    border-radius: 6px;
    background: #FFFFFF;
    padding: 5px;
    margin-right: 5px;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    align-items: center;
    text-align: center;
    color: #737373;
    margin-top: 5px;
}
.no-wrap{
    white-space: nowrap;
}
.book-details-key{
    margin-bottom: 0px;
    font-weight: normal;
    font-style: normal;
    font-size: 12px;
    line-height: 18px;
    color: #333333;
}
.book-details-value{
    font-family: IBM Plex Sans;
    margin-bottom: 0px !important;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: #333333;
}
.book-ellipsis{
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.book-series-name{
    cursor: pointer;
    &:hover{
        text-decoration: underline;
    }
}
.title-container{
    padding-right: 34px;
}
.add-cart-button{
    height: 40px;
    margin-top: -2%;
    margin-left: 10px;
}

.back-button{
    position:absolute;
    top: -40px;
    border: none;
    background: transparent;
    font-family: IBM Plex Sans;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #000000;
    cursor: pointer;
}
.list{
   position: relative;
   .left-margin{
    @media only screen and (min-width: 992px){
        padding-left: 15px;
    }
   }
}
.checkbox-container{
    position: absolute;
    bottom: 14%;
    left: 6%;
    zoom:1.5;
    @media only screen and (min-width: 1380px){
        left: 5%;
        bottom: 13%;
    }
    @media only screen and (min-width: 1580px){
        left: 4%;
        bottom: 13%;
    }
}
.selected-book{
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 10;
}
.ant-checkbox-checked .ant-checkbox-inner{
    background-color: #1C1C1C;
    border-color: #1C1C1C;
}
.ant-checkbox-input:focus + .ant-checkbox-inner{
    border-color: #1C1C1C;
}
.ant-input-number{
    background: #FFFFFF;
    border: 1px solid #DEDEDE;
    box-sizing: border-box;
    border-radius: 100px;
    max-width: 65px;
    max-height: 38px;
    &:hover{
        border: 1px solid #b8b6b6;
    }
}
.ant-input-number-focused{
    box-shadow: none;
}
.selected{
    opacity: 0.6;
}
.cancel{
    button{
    height: 35px;
    }
    padding-left: 24px;
    padding-right: 24px;
}
.info-icon{
    padding-left: 2px;
    padding-bottom: 2px;
    cursor: pointer;
}
.ant-popover{
    border-radius: 10px !important;
    padding-top: 0px !important;
}
.ant-popover-content{
    background: #ffffff;
    box-sizing: border-box !important;
    border: 1px solid rgba(0, 0, 0, 0.2) !important;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1) !important;
    border-radius: 10px !important;
}
.ant-popover-arrow{
    display: none !important;
}
.ant-popover-inner{
border-radius: 10px !important;
}
.ant-popover-inner-content{
    border-radius: 10px !important;
}
.ant-input-number-handler{
color: #B1B1B1 !important;
border-left: none !important;
}
.ant-input-number-handler-wrap{
    opacity: 10 !important;
    margin-right: 6px !important;
    border-top-right-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
}
.ant-input-number-handler-up{
    height: 50% !important;
    border-top-right-radius: 10px !important;
}
.ant-input-number-handler-down{
    height: 50% !important;
    border-bottom-right-radius: 10px !important;
}
.ant-input-number-handler-up:hover {
    height: 50% !important;
}
.ant-input-number-handler-down:hover {
    height: 50% !important;
}
.book-cart{
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 0px;
    color: #4F4949;
}
.carts-divider{
    margin-top: 10px !important;
    margin-bottom: 10px !important;
}
.spinner-container{
    text-align: center;
    padding-top: 12px;
    padding-bottom: 24px;
}
.book-state{
    margin-left: 8px;
    padding: 2px;
    height: 11px;
    max-width: 39px;
    font-style: normal;
    font-weight: bold;
    font-size: 7px;
    line-height: 9px;
    color: #FFFFFF;
    background: #F94031;
    border-radius: 2px;
}
.notification{
    width: 220px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1) ;
    border-radius: 10px ;
}
.ant-message{
    top: 55px !important;
    left: 30px !important;
}
.ant-message-notice-content{
    color: #29342f !important;
    font-family: IBM Plex Sans !important;
    font-size: 14px;
    border-radius: 10px;
}
.copies-text{
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #4F4949;
    margin-bottom: 0px;
}

.book-author{
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    cursor: pointer;
    &:hover{
        text-decoration: underline;
    }
}
.book-subTitle {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
}
.editor-Button{
    float: right;
    cursor: pointer;
}
.book-ppu-price {
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    color: #1C1C1C;
}
.book-price-fix {
    display: flex;
    justify-content: flex-end;
    padding-right: 20px;
}
.bookListTitleStyle{
    text-align: center;
    display: flex;
    justify-content: center;
    padding-top:10PX ;
    font-family: IBM Plex Sans !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 14px !important;
    line-height: 18px !important;
    color: #737373 !important;
  }

  .bookListTitleCountStyle{
    display: flex;
    align-items: center;
  }
  .book-narrator {
      padding-left: 20px;
  }
  .bookHover{
      cursor: pointer;
      padding-bottom: 20px;
  }
  .add_toShelves_button {
    background-color: #fff !important;
    color: #1c1c1c;
    box-shadow: 0px 94px 138px rgb(0 0 0 / 9%), 0px 47.0459px 69.0674px rgb(0 0 0 / 8%), 0px 28.3382px 41.603px rgb(0 0 0 / 6%), 0px 18.1606px 26.6614px rgb(0 0 0 / 7%), 0px 11.7703px 17.2797px rgb(0 0 0 / 10%), 0px 7.40924px 10.8774px rgb(0 0 0 / 9%), 0px 4.25707px 6.24974px rgb(0 0 0 / 7%), 0px 1.87365px 2.75067px rgb(0 0 0 / 5%);
    &:hover {
        background-color: #fff !important;
    }
}
.add_toShelves_single_button{
    @media screen and (min-width: 1000px) {
        position: absolute !important;
      }
}
.my-collection-title-container{
padding-bottom: 10px !important;
}
.add-to-shelf-col-padding{
    padding-top: 5px;
    position: relative;
}
.add-toCart-shadow{
    box-shadow: 0px 94px 138px rgb(0 0 0 / 9%), 0px 47.0459px 69.0674px rgb(0 0 0 / 8%), 0px 28.3382px 41.603px rgb(0 0 0 / 6%), 0px 18.1606px 26.6614px rgb(0 0 0 / 7%), 0px 11.7703px 17.2797px rgb(0 0 0 / 10%), 0px 7.40924px 10.8774px rgb(0 0 0 / 9%), 0px 4.25707px 6.24974px rgb(0 0 0 / 7%), 0px 1.87365px 2.75067px rgb(0 0 0 / 5%);
}
.cart_button_divider{
    border-left: 1px solid #fff !important;
    height: 39px;
    opacity: 0.7;
    margin-top: 0px !important;
    margin-bottom:0px !important ;
}
.shelf_button_divider{
    border-left: 1px solid #1c1c1c !important;
    height: 39px;
    opacity: 0.6;
}
.add-main-cart-button{
    cursor: pointer;
    @media screen and (max-width: 816px) {
        width: 65% !important;
      }
}
.cart-tomain-dropdown{
    cursor: pointer;
}
.unSelectAllCheckbox{
    zoom: 1.5;
}
.addToMainShelfPadding{
    @media only screen and (max-width: 973px) {
    padding-left: 12px;
    }
}
.disable-on-unselectall{
    cursor: not-allowed !important;
}
.title-cover div:last-of-type {
    padding-bottom: 10px;
}
.verticalDivider-booklist {
    height: 150px;
    @media only screen and (max-width: 767px) {
        display: none;
    }
}
.book-genre-list {
    display: inline-flex;
    margin-bottom: 5px;
}
.add_shelv_btn {
    margin-top: 5px;
}
.bottom-paging{
    margin: auto;
    .pageStyle {
        padding-top: 15px;
      }
}

.my-group-table{
    margin-top: 2vw;
    .subHeading{
        color: #665c5c;
    }
    .tableValues{
        color: #000000;
        font-weight: 800;
    }
    .cloud-link-info-icon{
        position: relative;
        z-index: 1;
        padding-left: 2px;
        padding-bottom: 2px;
        cursor: pointer;
    }
    .custom-popover{
        white-space: nowrap;
        font-size: 11px;
    }
    .ant-popover-inner-content{
        padding: 9px 12px;
    }
    table,th,td {
        border: 1px solid grey;
        font-size: 11px;
        font-family: IBM Plex Sans !important;
    }
    table {
        margin-top: 0.5em;
        line-height: 1.1800;
        width: 13vw;
        @media only screen and (max-width: 1200px) {
            width: 12vw
        }
        @media only screen and (max-width: 1100px) {
            width: 11vw
        }
        @media only screen and (max-width: 1000px) {
            width: 17vw
        }
        @media only screen and (max-width: 1000px) {
            width: 17vw
        }
        @media only screen and (max-width: 990px) {
            width: 24vw
        }
}
    td {
        text-align: center;
    }
}

.pr-40{
    padding-right: 40px !important;
}