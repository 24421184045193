.description-row {
  margin-top: 30px;
  padding-right: 24px;
}
.purchaseFixedScroll{
  .ant-table-content{
    overflow-x: scroll !important;
  }
}
.mb-50{
  margin-bottom: 50px;
}
.mb-100{
  margin-bottom: 100px;
}
.mb-20{
  margin-bottom: 20px;
}
.switchViewButtons {
  .ant-radio-button-wrapper:hover {
    position: relative;
    color: #1c1c1c;
  }
  .ant-radio-button-wrapper:first-child {
    border-left: 1px solid #1c1c1c;
    border-radius: 5px 0 0 5px;
  }
  .ant-radio-button-wrapper:last-child {
    border-radius: 0 5px 5px 0;
  }
  .ant-radio-button-wrapper {
    color: rgba(0, 0, 0, 0.85);
    border: 1px solid #1c1c1c;
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    z-index: 1;
    color: #ffffff;
    background: #1c1c1c;
    border-color: #1c1c1c;
  }
  .ant-radio-button-wrapper:last-child {
    border-radius: 0 5px 5px 0;
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background-color: #1c1c1c;
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
    color: #ffffff;
    border-color: #1c1c1c;
  }
  .ant-radio-button-wrapper-checked:not([class*=" ant-radio-button-wrapper-disabled"]).ant-radio-button-wrapper:first-child {
    border-right-color: #080808;
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    background: #1c1c1c;
  }
  .ant-radio-button-wrapper:first-child {
    border-left: 1px solid #1c1c1c;
    border-radius: 5px 0 0 5px;
  }
}
.alerlBuyerModel {
  .ant-modal-title {
    color: rgb(202 11 19);
  }
  .ant-modal-body {
    padding: 24px 5px;
    .header {
      padding: 20px 10px;
      text-align: center;
      font-weight: 600;
      font-size: 18px;
    }
    .bodyItemVerify {
      height: 100px;
      p {
        font-size: 13px;
        font-weight: 600;
        text-align: center;
      }
    }
    .btn-section {
      text-align: center;
      .CustomizeButtonSpotlight {
        cursor: pointer;
        border-radius: 23px;
        width: 165px;
        border-color: #090808;
        font-family: IBM Plex Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
      }
      .CustomizeButtonSpotlight.ant-btn {
        &:hover {
          border-color: #090808;
          color: #090808;
        }
      }
    }
  }
  .ant-modal-footer.first {
    display: none !important;
  }
  .ant-modal-footer {
    display: none;
  }
}
.carts-divider-top {
  margin-top: 8px;
  margin-bottom: 12px;
}
.carts-divider-bottom {
  margin-top: 0px;
  margin-bottom: 0px;
}
.cart-descritption {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #4f4949;
}
.export-link {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  text-align: right;
  text-decoration-line: underline;
  color: #000000;
  cursor: pointer;
  &:hover {
    text-decoration-line: underline;
    color: #000000;
  }
}
.button-type {
  height: 32px;
  border: 0.5px;
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;

  border-radius: 4px;
  padding: 0px 10px;
}

.viewer {
  background: rgba(255, 0, 0, 0.07);
}
.editor {
  background: rgba(59, 159, 202, 0.2);
  cursor: pointer;
}
.edit-icon {
  padding-right: 10px;
}
.down-icon {
  padding-left: 10px;
}
.customize-link {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  text-decoration-line: underline;
  color: #000000;
  cursor: pointer;
}
.cart-items {
  height: 50vh;
}
.detail-footer {
  padding: 12px 24px 24px 24px;
  width: 100%;
  position: absolute;
  bottom: 0;
  background: #ffffff;
  z-index: 999;
  box-shadow: 0px -1px 0px #dedede, 0px 1px 0px #dedede;
}

.button-row{
  margin: auto;
  margin-bottom: 16px;
}
.footer-label {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #4f4949;
  margin-bottom: 4px;
}
.mark-ready-button {
  margin-top: 12px;
  white-space: nowrap;
  height: 40px;
}
.purchaseBtn {
  display: flex;
  justify-content: flex-end;
  button {
    width: 100%;
    margin: 5px;
  }
}
.removeBtnLink {
  display: flex;
  // justify-content: flex-end;
  align-items: center;
  button {
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    text-decoration-line: underline;
    color: #ee0000;
    background: transparent !important;
    border: 0px !important;
    box-shadow: none;
    border-radius: 0px !important;
    text-align: right;
    width: 120px;
    height: 20px !important;
    &:hover {
      background: transparent !important;
      box-shadow: none !important;
      border-radius: 0px !important;
      border: 0px !important;
    }
  }
}
.POReference button {
  color: #090808;
  width: 140px;
}
.cart-table {
  thead {
    tr {
      th {
        font-family: IBM Plex Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 14px;
        color: #1c1c1c;
      }
    }
  }
  tbody {
    font-size: 12px;
    tr:last-child {
      td {
        border-bottom: none;
      }
    }
    .ant-table-row-selected {
      td {
        background: #fafafa !important;
      }
    }
  }
  .ant-popover-inner-content {
    border-radius: 10px !important;
    position: fixed;
    background: white;
    border: 1px solid #8080804a;
  }
}
.CartCoverColm {
  max-width: 100px;
  position: relative;
  width: 100%;
  .cart-book-label {
    height: 24px;
    width: 24px;
    position: absolute;
    top: 8px;
    left: 70px;
  }
}
.column {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #1c1c1c;
  margin-bottom: 0 !important;
}

.bold-titles {
  font-weight: 600;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
.item-cover {
  width: 100px;
}
.autocartHistoryTable {
  .item-cover {
    width: 48px !important;
    height: 72px;
    position: relative;
  }
}
.cart-column-filter {
  top: 0px !important;
}
.ready-icon {
  padding-right: 16px;
}
.cart-quantity {
  width: 70px;
  height: 28px;
  max-width: 70px;
}

.cart-quantity::-webkit-outer-spin-button {
  opacity: 1;
}

.cart-newQ {
  padding: 0 11px;
  text-align: left;
  border-radius: 20px;
  font-family: IBM Plex Sans;
  font-size: 14px;
  border: 1px solid #f0f0f0 !important;
  &:focus {
    border: 1px solid #f0f0f0 !important;
  }
  &:focus-visible {
    border: 1px solid #f0f0f0 !important;
    outline: none !important;
  }
}
.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: black !important;
}
.restriction-label {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #000000;
  opacity: 0.8;
  margin-bottom: 3px;
}
.restriction-value {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #b1b1b1;
  opacity: 0.8;
  margin-bottom: 3px;
}
.book-state-carts {
  padding: 2px;
  height: 11px;
  max-width: 39px;
  font-style: normal;
  font-weight: bold;
  font-size: 7px;
  line-height: 9px;
  color: #ffffff;
  background: #f94031;
  border-radius: 2px;
}
.readyLabel {
  height: 32px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #11853f;
  background: #5aed95;
  opacity: 0.6;
  border-radius: 4px;
  padding-top: 6px;
  padding-left: 12px;
}

.purchase-modal {
  .ant-modal {
    max-width: 550px;
    width: 100%;
  }
  .purchase-title {
    text-align: center;
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    color: #1c1c1c;
  }
  .purchaseNote {
    margin-top: 8px;
    background: #dedede;
    border-radius: 4px;
    padding: 8px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 22px;
    color: #4f4949;
    text-align: center;
  }
  .purchase-content {
    padding: 30px 0px;
    border-top: 1px solid rgba(3, 3, 3, 0.2);
    border-bottom: 1px solid rgba(3, 3, 3, 0.2);
  }
  .ant-modal-body {
    padding-top: 50px;
    padding-right: 50px;
    padding-left: 50px;
    padding-bottom: 0px;
  }
  .ant-modal-footer {
    padding-top: 30px;
    border-top: none;
    text-align: center;
    justify-content: center;
    padding-bottom: 46px;
    .confirm-button {
      height: 40px;
      width: 195px;
    }
    .cancel-button {
      border: none;
      background: transparent;
      font-style: normal;
      margin-top: 25px;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      text-align: center;
      color: #ff0000;
    }
  }
  .purchase-description {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #4f4949;
    font-family: IBM Plex Sans;
  }
}

.deactivate-user-modal{
  .ant-modal {
    max-width: 750px;
    width: 750px !important;
    @media screen and (max-width: 950px) {
      width: 700px !important;
    }
  }
  .no-wrap{
    white-space: nowrap;
  }
  .purchaseNote {
    margin-top: 8px;
    background: #dedede;
    border-radius: 4px;
    padding: 8px 15px 8px 0px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 22px;
    color: #4f4949;
    @media screen and (max-width: 950px) {
      font-size: 13px;
    }
    ul{
      margin-top: 1em;    
    }
  }
  .ant-modal-body {
    padding-top: 50px;
    padding-right: 50px;
    padding-left: 50px;
    padding-bottom: 0px;
  }
  .ant-modal-footer {
    padding-top: 30px;
    border-top: none;
    text-align: center;
    justify-content: center;
    padding-bottom: 46px;
    .confirm-button {
      height: 40px;
      width: 195px;
    }
    .cancel-button {
      border: none;
      background: transparent;
      font-style: normal;
      margin-top: 25px;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      text-align: center;
      color: #ff0000;
    }
  }
}
.done-button {
  padding-top: 30px;
  border: none;
  background: transparent;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  text-decoration-line: underline;
  color: #000000;
  cursor: pointer;
}

.footer {
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  padding-top: 30px;
}
.column-filter-container {
  // text-align: right;
  padding-right: 5px;
  display: flex;
  align-items: baseline;
  justify-content: flex-end;
}
.remove-content {
  padding: 10px 0px;
  display: flex;
  justify-content: center;
}
.removeModalBtn {
  cursor: pointer;
}
.left-sorter .ant-table-column-sorters {
  display: flex !important;
  flex-direction: row-reverse !important;
}
.left-sorter .ant-table-column-title {
  padding-left: 5px;
}
.edit-PO-reference {
  .ant-modal-body {
    padding-right: 11px;
    padding-left: 11px;
  }
  .PO-reference-input {
    width: auto;
    margin-left: 23px;
  }
  .PO-reference-text {
    text-align: center;
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 19px;
    margin-left: 39px;
    color: #1c1c1c;
    display: flex;
  }
}
.moveToCartButton {
  // padding-left: 27px;
  // margin-right: 20px;
}
.moveCart {
  display: none !important;
}
.moveCartBefSelect {
  margin-top: 20px;
  padding-bottom: 12px;
  text-align: center;
  background: #dedede;
  font-weight: bold;
  padding-top: 12px;
  width: 100%;
}
.moveCartTitle {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  color: #4f4949;
  display: flex;
  justify-content: center;
}
.moveTitleText1 {
  margin-top: 20px;
  padding-bottom: 12px;
  text-align: center;
  background: #dedede;
  font-weight: bold;
  padding-top: 12px;
}
.moveTitleText2 {
  text-align: center;
  width: 100%;
  background: #dedede;
  font-weight: bold;
  padding-bottom: 12px;
}
.moveConfirm {
  margin-bottom: 20px;
}
.moveCartCancel {
  cursor: pointer;
  color: red;
  font-weight: bold;
}
.cart_update_quantity {
  padding-right: 4px;
  padding-left: 6px;
}
.cart_cancel_btn {
  padding-left: 4px;
}
.cart-table .ant-table-body {
  padding-bottom: 20px !important;
  @media screen and (max-width: 1200px) {
    padding-bottom: 35px !important;
  }
}
.editIconAlign {
  padding-left: 13px;
  padding-bottom: 3px;
}
