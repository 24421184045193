.customPassword {
  height: 54px !important;
  color: #1C1C1C;
  background: #ffffff;
  &:focus {
    border-color: #55C1F6 !important;
  }
  }
  .ant-form-item-has-error .ant-input {
    background-image: url('../../../../../assets/images/errorIcon.svg');
    background-repeat: no-repeat;
    background-position: right 20px center;
  }
  .ant-input {
    caret-color: red;
    padding-left: 20px;
    padding-right: 20px;
    background-image: none;
    background-repeat: no-repeat;
    background-position: right 20px center;
  }
  .ant-input:hover{
    cursor: pointer;
    caret-color: red;
    border: 1px solid #CBCBCB;
  }
  .loginLabelName{
    font-family: IBM Plex Sans !important;
    font-style: normal;
    font-weight: 500;
    font-size: 0.75rem !important;
    line-height: 20px !important;
    color: #1C1C1C !important;
  }
  .changePasswordLabel{
    font-family: IBM Plex Sans !important;
    font-style: normal;
    font-weight: 500;
    font-size: 0.75rem !important;
    line-height: 20px !important;
    color: #1C1C1C !important;
  }
  .changePasswordLabel label {
    font-family: IBM Plex Sans !important;
    font-style: normal;
    font-weight: 500;
    font-size: 0.75rem !important;
    line-height: 20px !important;
    color: #1C1C1C !important;
  }

  .customPassword::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 0.875rem;
    line-height: 20px;
    color: #737373;
  }
  .customPassword::-moz-placeholder { /* Firefox 19+ */
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 0.875rem;
    color: #737373;
  }
  .customPassword:-ms-input-placeholder { /* IE 10+ */
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 0.875rem;
    line-height: 20px;
    color: #737373;
  }
  .customPassword:-moz-placeholder { /* Firefox 18- */
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 0.875rem;
    color: #737373;
  }