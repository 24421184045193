.ellipsis{
    .text-overflow{
        display: block;
         white-space: nowrap;
         overflow: hidden;
         text-overflow:ellipsis;
         bottom: 0;
         position: absolute;
     
    }

}