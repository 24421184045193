.insightsMarcRecord {
  // height: 100%;
  .marc_records_tab {
    .ant-tabs-nav {
      margin: 0 !important;
      top: -10px;
    }
    .outerLabel {
      margin-top: 10px !important;
    }
    .marc_isbn_screen {
      padding-top: 3% !important;
    }
  }
  .ant-tabs-tab-btn {
    font-size: 16px;
    font-weight: 400;
  }
  .ant-tabs-tab-active {
    font-weight: 500;
  }
  .insightsMarcScreen {
    
    .outerLabel {
      font-weight: 700;
      display: block;
      margin: 20px 0px 5px 0;
    }
    .radioDisplyBlock {
      .ant-radio-wrapper {
        display: block !important;
      }
    }
    .Marc-datepicker {
      border: 1px solid rgba(115, 115, 115, 0.4);
      border-radius: 100px;
      padding-left: 20px;
      margin-left: 5px;
      height: 34px;
    }
    .isbn-marc-Box {
      border: 1px solid #dbcaca;
      padding: 20px 10px;
      margin-bottom: 20px;
      width: 70%;
    }
    .ant-checkbox-wrapper {
      margin-top: 10px;
    }
  }
  .loginButton {
    max-width: 400px;
    margin-bottom: 20%;
    margin-top: 3%;
  }
  .fontStronger {
    font-weight: 700;
  }
  .pl-30 {
    padding-left: 30px;
  }
  .mb-2 {
    margin-bottom: 2%;
  }
  .text-center {
    text-align: center;
  }
}

.ant-picker-dropdown {
  .ant-picker-cell-in-view.ant-picker-cell-today
    .ant-picker-cell-inner::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    border: 1px solid #101010 !important;
    border-radius: 2px;
    content: "";
  }
  .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner {
    background: #101010 !important;
  }
  .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    color: #fff;
    background: #101010;
  }
}
.ant-picker-range .ant-picker-active-bar {
  background: #333232 !important;
}
.statusDiv {
  border: 1px solid #101010;
  height: 15vh;
  margin-top: 5vh;
  width: 50%;
}
@media (max-width: 1600px) {
  .statusDiv {
    width: 90%; /* Increase width for smaller screens */
    max-width: 800px;
    height: 18vh;
  }
}

@media (max-width: 992px) {
  .statusDiv {
    width: 90%; /* Increase width for smaller screens */
    max-width: 800px;
    height: 24vh;
  }
}
@media (max-width: 768px) {
  .statusDiv {
    width: 90%; /* Increase width for smaller screens */
    max-width: 800px;
    height: 24vh;
    // padding: 15px;
  }
}
@media (max-width: 576px) {
  .statusDiv {
    width: 90%; /* Increase width for smaller screens */
    max-width: 800px;
    height: 28vh;
  }
}
@media (max-width: 392px) {
  .statusDiv {
    width: 90%; /* Increase width for smaller screens */
    max-width: 800px;
    height: 34vh;
  }
}

// .downloadMarc-btn .outerLabel-status {
//   font-weight: 700;
//   // display: block;
//   margin: 20px 0px 5px 0;
// }
.Pending {
  color: orange;
}
.Failed {
  color: red;
}
.complete {
  color: green;
}
.grey {
  color: grey;
}

/* to add scroll for different viewpoints */




@media (max-width: 1920px) and (max-height: 1080px) {
  .insightsMarcScreen {
    max-height: 740px!important;
    overflow-y: scroll!important;
  }
}

@media (max-width: 1745px) and (max-height: 980px) {
  .insightsMarcScreen {
    max-height: 660px!important;
    overflow-y: scroll!important;
  }
}
@media (max-width: 1536px) and (max-height: 864px) {
  .insightsMarcScreen {
    max-height: 500px!important;
    overflow-y: scroll!important;
  }
}
@media (max-width: 1280px) and (max-height: 720px) {
  .insightsMarcScreen {
    max-height: 380px!important;
    overflow-y: scroll!important;
  }
}

@media (max-width: 960px) and (max-height: 540px) {
  .insightsMarcScreen {
    max-height: 330px!important;
    overflow-y: scroll!important;
  }
}



.marc-div{
  margin-bottom: 20vh!important;
}

.marc-ok{
  margin-top: 10px;
  margin-bottom: 20vh!important;

}
.checkbox-disabled {
  opacity: 0.8; 
}

.checkbox-disabled .ant-checkbox-inner {
  background-color:lightgray; 
  border-color: #d9d9d9; 
}

