.shelfHeadWrapper {
  justify-content: space-between;
}
.shelvdetailUpdatingShelv {
  font-size: 17px;
  color: #737373;
  font-weight: 500;
  height: 200px;
  display: flex;
  padding-top: 156px;
  justify-content: center;
}
.shelvDetails {
  .ant-spin-nested-loading > div > .ant-spin {
    position: relative;
    top: 150px;
    left: 0;
    z-index: 4;
    display: block;
    width: 100%;
    height: 100%;
    max-height: 400px;
  }
}
.shelfHeadCol {
  display: flex;
  margin-right: 20px;
  .blue-tag {
    background-color: #4c8bf5 !important;
    &:hover {
      background-color: #4c8bf5 !important;
    }
  }
  .yellow-tag {
    background-color: #ffde30 !important;
    color: #1C1C1C;
    &:hover {
      background-color: #ffde30 !important;
    }
  }
  .green-tag {
    background-color: #14ae23 !important;
    &:hover {
      background-color: #14ae23 !important;
    }
  }
  .pink-tag {
    background-color: #ff8787 !important;
    &:hover {
      background-color: #ff8787 !important;
    }
  }
  button {
    height: 25px;
    border-radius: 0px;
    border: none !important;
    box-shadow: none;
    &:hover {
      border-radius: 0px;
      border: none !important;
      box-shadow: none !important;
    }
  }
  button:first-of-type {
    margin-right: 10px;
  }
}
.exportBtnWrapper {
  display: flex;
  justify-content: flex-end;
  margin-right: 10px;
  button {
    height: 35px;
  }
  button:first-of-type {
    margin-right: 10px;
  }
}
.filter-wrapper {
  display: flex;
  align-items: center;
}
.shelvesfooterbtn {
  justify-content: flex-end;
  margin-top: 5px;
  align-items: baseline;
  .exportBtnWrapper {
    padding-right: 10px;
  }
  .removeBtnLink {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100% !important;
    button {
      font-family: IBM Plex Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      text-decoration-line: underline;
      color: #ee0000;
      background: transparent !important;
      border: 0px !important;
      box-shadow: none;
      border-radius: 0px !important;
      text-align: right;
      width: 120px;
      height: 20px !important;
      &:hover {
        background: transparent !important;
        box-shadow: none !important;
        border-radius: 0px !important;
        border: 0px !important;
      }
    }
  }
}


.shelvestable {
  height: 66vh !important;
  @media screen and (max-height: 980px) {
    height: 64vh !important;
  }
  @media screen and (max-height: 940px) {
    height: 60vh !important;
  }
  @media screen and (max-height: 880px) {
    height: 56vh !important;
  }
  @media screen and (max-height: 780px) {
    height: 50vh !important;
  }
  @media screen and (max-height: 720px) {
    height: 52vh !important;
  }
}
.shelvesDiscription-row {
  margin-top: 14px;
}
.ShelveExportRow {
  display: flex;
  align-items: center;
}
.shelveDetailBtn {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  width: 81px;
  height: 25px;
}
.shelveTableHeight {
  height: 66vh !important;
  @media screen and (max-height: 980px) {
    height: 70vh !important;
  }
  @media screen and (max-height: 940px) {
    height: 68vh !important;
  }
  @media screen and (max-height: 880px) {
    height: 66vh !important;
  }
  @media screen and (max-height: 780px) {
    height: 61vh !important;
  }
  @media screen and (max-height: 720px) {
    height: 55vh !important;
  }
}
.shelvesExport {
  width: 100%;
  height: 30px;
  border: 1px solid #1c1c1c;
  background: transparent;
  box-sizing: border-box;
  border-radius: 60px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1c1c1c !important;
  font-family: IBM Plex Sans;
}
.linkColor {
  color: #1c1c1c;
  cursor: pointer;
}
.removebtnMargin {
  margin-right: 1%;
}
.removeBtnAdjusted {
  justify-content: flex-start !important;
}
.unpublish-button {
  width: 48%;
}
.shelves-edit-dropdown div {
  width: 160px !important;
}
.savebtn{
  height: 38px !important;
  margin-top: 20px !important;
}
.editCancelBtn{
  color: #EE0000;;
    border: none;
    font-weight: bold;
    background: none;
    margin-top: 20px;
    cursor: pointer;
}
.editDiv{
  text-align: center;
}
.editShelfBtn{
    color: #1c1c1c ;
    border: none;
    font-weight: bold;
    font-size: 16px;
    background: none;
    text-decoration-line: underline;
    cursor: pointer;
}
.shelves-edit-dropdown div div div div.ant-select-dropdown {
width: 160px !important;
}
.shelves-addItem{
  padding-left: 10px;
  button {
  background: transparent !important;
    color: black;
    height: 32px;
    box-shadow: none !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    &:hover {
      background-color: transparent !important;
      box-shadow: none !important;
    }
}
}
.shelves-buttons {
  margin-right: 0px;
}


.shelves-edit-dropdown div div div div.ant-select-dropdown {
  width: 160px !important;
  }
  .shelves-addItem{
    padding-left: 10px;
    button {
    background: transparent !important;
      color: black;
      height: 32px;
      box-shadow: none !important;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      &:hover {
        background-color: transparent !important;
        box-shadow: none !important;
      }
  }
  }
.shelves-buttons {
    margin-right: 0px;
}
.automated-divider-bottom{
  margin-top: 197px;     
}
.shelfDetailsBtn {
  cursor: text; 
}
.shelveExport {
  font-weight: 600;
  text-decoration: underline;
  &:hover {
    color: #1c1c1c;
    cursor: pointer;
    text-decoration: underline;
  }
  &:focus{
    text-decoration: underline;
  }
}

.shelfMenuGroup {
  padding-left: 25px;
}
.editShelfSaveChange{
  cursor: not-allowed;
}
.saveChangeBtnRemoved{
  width:165px
}
.shelveDetailCol {
  cursor: default;
  text-decoration: none;
  &:hover {
    text-decoration: none;
    cursor: default;
  }
}