.customInput{
  height: 54px !important;
  color: #1C1C1C;
  background: #ffffff;
  &:focus {
    border-color: #55C1F6 !important;
  }
}
.labelName label {
    font-family: IBM Plex Sans !important;
    font-style: normal;
    font-weight: 500;
    font-size: 0.75rem !important;
    line-height: 20px !important;
    color: #1C1C1C !important;
    height: auto !important;
  }

  .ant-form-item-has-error .ant-input {
    background-repeat: no-repeat;
    background-position: right 20px center;
    border: 1px solid #ee0000 !important;
    color: #ee0000;
  }
  .ant-input {
    padding-left: 28px;
    border: 1px solid rgba(115, 115, 115, 0.4);
    border-radius: 100px;
    &:hover{
      border: 1px solid #1C1C1C;
    }
    &:active{
      border: 1px solid rgba(115, 115, 115, 0.4);
      color: #1C1C1C
    }
    &:focus{
      border: 1px solid rgba(115, 115, 115, 0.4);
      color: #1C1C1C
    }
  }
  .loginLabelName{
    font-family: IBM Plex Sans !important;
    font-style: normal ;
    font-weight: 500;
    font-size: 0.75rem !important;
    line-height: 20px !important;
    color: #1C1C1C !important;
  }
  
  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
    display: inline-block;
    margin-right: 4px;
    color: #EE0000;
    font-size: 0.75rem;
    font-family: IBM Plex Sans;
    line-height: 1;
    content: '*';
  }
.pt-15{
    padding-top: 15px;
}
.pb-15 {
    padding-bottom: 15px;
}
  .ant-form-vertical .ant-form-item-label {
    padding-bottom: 5px !important;
  }
  .loginLabelName label{
    font-family: IBM Plex Sans !important;
    font-style: normal;
    font-weight: 500;
    font-size: 0.75rem !important;
    line-height: 20px !important;
    color: #1C1C1C !important;
    padding-top: 10.295%;
    height: auto !important;
  }
  .ant-form-item-explain.ant-form-item-explain-error {
    color: #EE0000 !important;
    font-family: IBM Plex Sans !important;
    font-style: normal;
    font-weight: normal;
    font-size: 0.75rem !important;
    line-height: 20px !important;
}
.loginError input {
 border: 1px solid #EE0000;
}
.mb-0 {
  margin-bottom: 0px;
}
