.ppu-wrapper {
    background-color: #fafafa;
    margin-top: -15px;
    max-height: 75vh;
}

.label_val{
    width: 100%;
    height: 20px;
    cursor: pointer;
}

.mr0 {
    margin-right: 0px !important;
}
.ml0 {
    margin-left: 0px !important
}
.ppu-Middle-line {
    opacity: 0.1;
    border: 1px solid #000000;
align-self: stretch;
}
.a-center {
    align-items: center;
}
.currencyWrapper {
    display: flex;
    justify-content: flex-end;
}
.mt-5 {
    margin-top: 5px;
}
.mt-6{
    margin-top: 6px;
}
.pl-10 {
    padding-left: 10px;
}
.pt-8{
    padding-top: 8px;
}
.pt-3 {
    padding-top: 3px;
}
.mt-15 {
    margin-top: 15px;
}
.ptb-25 {
    padding-top: 25px;
    padding-bottom: 25px;
}
.whitebg {
    background-color: #ffffff;
}
.mt-12 {
    margin-top: 12px;
}
.pb-25 {
    padding-bottom: 25px;
}
.ebookTitle {
    padding-left: 13px;
    padding-right: 13px;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
}
.ppu-switch {
    height: 18px;
    padding-bottom: 20px;
    position: absolute;
    margin-top: 5px;
    min-width: 38px;
    .ant-switch-handle {
        height: 16px;
    }
}
.switch-text {
    margin-left: 35px;
    font-size: 12px;
    color: #4F4949;
    opacity: 0.4;
}
.switchSec-text {
    font-size: 12px;
    color: #4F4949;
    opacity: 0.4;  
}
.pb-2 {
    padding-bottom: 2px;
}
.switch-wrapper .ppu-switch {
    background-color: #FB0D1B;
}
.switch-wrapper .ant-switch-checked {
    background-color: #14ae23 !important;
}
.ppu-Sec-switch {
    height: 18px;
    padding-bottom: 20px;
    min-width: 38px;
    .ant-switch-handle {
        height: 16px;
    }
}
.switch-wrapper .ppu-Sec-switch {
    background-color: #FB0D1B;
}
.alignBase {
    align-items: baseline;
}
.ml-20 {
    margin-left: 20px;
}
.pl-20 {
    padding-left: 20px;
}
.pr-20 {
    padding-right: 20px;
}
.pb-9 {
    padding-bottom: 9px;
}
.ppu-price-text {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    align-items: center;
    color: #1C1C1C;
}
.mr-20 {
    margin-right: 20px;
}
.footer-ppu {
    width: 100%;
}
.updateCardWrapper {
    border: 1px solid #f0f0f0;
    padding: 10px;
    padding-bottom: 0px;
    div {
        padding-top: 5px;
        font-weight: 600;
    }
}
.presentCardN0 {
 color: #FB0D1B;   
 border-bottom: 1px solid #f0f0f0;
 padding-top: 5px;
 padding-bottom: 5px;
}
.updateCardText {
    margin-top: 5%;
    padding-left: 20px;
 p {
     font-weight: 500;
 }
}
.patronDetail{
    display: inline-block;
    .email{
        margin-left: 50px;
    }
}
.patronStatus {
    color: #4F4949;
    opacity: 0.6;
}
.updateCardScroll {
    max-height: 60vh;
}
.patronBtn {
    padding-bottom: 100px;
}
.holdTable thead tr th {
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    color: #1c1c1c;
}
.patronLoader {
    height: 60vh;
}
.ebookMonthlyBudget {
    margin-top: -10px;
    margin-left: 10px;
    color: #EE0000;
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 0.75rem;
    line-height: 20px;
    margin-bottom: 10px;
}
.ebooktitleprice {
    margin-top: -10px;
    margin-left: 10px; 
    color: #EE0000;
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 0.75rem;
    line-height: 20px;
    margin-bottom: 10px;
}
.ppu-btn {
    cursor: not-allowed;
}
.ppuLabel .ant-input-disabled {
    border: 1px solid #d9d9d9;
    cursor: not-allowed;
}
.onFieldDisable .ant-input-disabled {
    border: 1px solid #d9d9d9;
    cursor: not-allowed;
}
.ppuDropdown .ant-select-disabled{
    cursor: not-allowed;
}
.popover-content-width div.ant-popover {
    width: 15vw;
}

.ppuzindex {
    z-index: 4 !important;
}
.labeltext {
    z-index: 3 !important;
}
.labelCover input {
    z-index: 2 !important;
}
.updateCardNo{
    padding-top: 25px;
    height: 23vh;
}
.patronBtnSize Button{
    width: 68%;
    height: 35px;
}
.ppuMandatoryField{
    &:hover{
        border-color: red !important;
    } 
border-color: red;
}