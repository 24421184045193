.date-Range{
    display: flex;
    justify-content: flex-start;
}
.heightAutoHistory{
  height: 45vh !important;
}
.history-dropdown{
    border: 1px solid rgba(115, 115, 115, 0.4);
    border-radius: 100px;
    margin-bottom: 12px;
    padding-left: 8px;
    width: 30% !important;
    text-align: center;
}
  .historyBold{
    font-size: 12px !important;
    font-family: IBM Plex Sans;
    font-style: normal;
    line-height: 14px;
  }
.scrollContentAutoCartHistory {
    max-height: 65vh !important;
    overflow-y: scroll;
    padding-right: 20px;
  }

.filters-contatiner-history{
    padding-left: 4px;
    align-items: center;
}
.filters-history{
    background: #DEDEDE;
    border-radius: 100px;
    height:32px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px !important;
    line-height: 12px !important;
    display: flex;
    align-items: center;
    text-align: center;
    color: #000000;
    width: 20% !important;
    display: flex;
    justify-content: center;
}
.HistorytableStyle{
margin-top: 10px;
}
.historySearch{
    width: 70px;
    height: 35px;
    font-size: 13px;
}
.history-datepicker {
    border: 1px solid rgba(115, 115, 115, 0.4);
    border-radius: 100px;
    margin-left: 20px;
    margin-right: 20px;
    padding-left: 20px;
    height: 34px;
    width: 45%;
    .ant-picker-suffix{
      display: flex;
      align-items: center;
    }
}
.history-editor-Button {
    float: right;
    cursor: pointer;
    margin-right: 7px;
}
.headerCenter{
    align-items: center;
    display: flex;
    justify-content: flex-start;
    margin: 30px 0;
}
.historyTable {
    thead {
      tr {
        th{
          font-weight: bold;
        }
        th:first-child {
          .ant-table-column-title {
            font-family: IBM Plex Sans;
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 14px;
            color: #1c1c1c;
          }
        }
      }
    }
    tbody {
      tr {
        td:first-child {
          p {
            font-weight: 600;
            cursor: pointer;
            &:hover {
              text-decoration: underline;
            }
          }
        }
        td:nth-child(4) {
          p {
            font-weight: 600 !important;
            font-size: 12px !important;
            font-family: IBM Plex Sans;
            font-style: normal;
            line-height: 14px;
            cursor: pointer;
            &:hover {
              text-decoration: underline;
            }
          }
        }
        td {
          p {
            font-weight: 400;
            cursor: default;
            &:hover {
              text-decoration: none;
            }
          }
        }
      }
    }
  }
.tableHeight {
    height: 66vh !important;
}
@media screen and (max-height: 847px) {
    .tableHeight {
        height: 52vh !important;
    }
}
.historybtn {
  cursor: not-allowed;
}
.export-list-history{
  padding-bottom: 12px;
  padding-left: 5px;
}
