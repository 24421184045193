.search-position{
  position: relative;
}
.search-renderer {
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    width: 100%;    
    z-index: 10;
    top: 11px;
    right: 0;
    position: absolute;
    display: flex;
    flex-direction: column;
    font-family: IBM Plex Sans;
    background-color: #ffffff;
    .resetBtn{
      height: 40px;
      width: 40px;
      line-height: 18px;
      position: absolute;
      border-radius: 50%;
      border: none;
      color: gray;
      text-align: right;
      right: 0px;
      cursor: pointer;
      padding: 0 5px;
      margin: 0;
      background: transparent;
      span{
        border-radius: 50%;
        background: rgb(0 0 0 / 14%);
        color: white;
        padding: 2px 5px;
        font-size: 10px;
      }
    }
}
.search {
    height: 40px;
    width: 100%;
    outline: none;
    font-family: IBM Plex Sans;
    font-size: 14px;
    line-height: 18px;
    background-color: #ffffff;
    position:relative;
    padding-left: 30px;
    border: none;
    border-bottom-left-radius: 100px ;
    border-top-right-radius: 100px ;
    border-top-left-radius: 100px ;
    border-bottom-right-radius: 100px ;
    background: no-repeat 10px;
    background-image: url("../../../../../assets/icons/search.svg");
  }
  
.search-dropdown{
    max-height: 350px;
    margin-top: 0px;
    width: 100%;
    overflow-y: auto;
    font-family: IBM Plex Sans;
    background: #ffffff;
    .ant-spin-spinning{
      margin-left: 40%;
    }
    .search-options{
        display: flex;
        align-items: center;
        height: fit-content;
        padding-left: 10px;
        border-left: 2px solid transparent;
        cursor: pointer;
      &:hover{
        border-left: 2px solid red;
      }
    }
  }
  .advance-search{
    border-top: 1px solid rgba(115, 115, 115, 0.4);
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 10px;
    .link{
      padding-top: 12px;
      font-family: IBM Plex Sans;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      cursor: pointer;
      text-decoration-line: underline;
      color: rgba(0, 0, 0, 0.8);
      &:hover{
        color: rgba(0, 0, 0, 0.8);
        text-decoration-line: underline;
      }
    }
  }
  .searchPlaceholder::placeholder {
    font-size: 11px;
  }