.purchaseOuterBox {
  display: flex;
  justify-content: space-between;
  padding-right: 10px;
  align-items: center;
}

.pb-20{
  padding-bottom: 20px !important;
}

.dispalayBtnConsole{
  display: none;
}

.preSaleTitle {
  font-weight: bold !important;
  font-size: 12px !important;
  font-family: IBM Plex Sans;
  font-style: normal;
  line-height: 14px;
}
.purchase-Editor {
  top: 60px;
  width: 100%;
  text-align: right;
  margin-right: 10px;
  div{
    display: inline-block;
  }
  .exportColListCsv{
    width: 40%;
  }
}
.notFor_sale {
  color: red;
}

.title_colm {
  .book-name-link {
    font-weight: 600;
    cursor: pointer;
    font-family: IBM Plex Sans;
    font-style: normal;
    font-size: 12px;
    line-height: 14px;
    color: #1c1c1c;
  }
  .cover_conten {
    height: 100%;
    top: -10px;
    position: relative;
  }
  span {
    display: inline-table !important;
    margin-right: 5px;
    p {
      margin: 0 !important;
      font-size: 10px;
    }
  }
}
.boldTitles {
  font-weight: 600 !important;
}
.preSale-footer {
  span {
    display: inline-block;
    width: 40%;
    margin: 5px 5px;
  }
}
.editable-row .ant-form-item-explain {
  position: absolute;
  top: 100%;
  font-size: 12px;
}
.bold-titles-grey {
  font-weight: 600;
  cursor: Line pointer;
  color: grey !important;
}
.bold-titles-line-pointer{
  font-weight: 600;
  cursor: Line pointer;
}
.dateCover {
  visibility: hidden;
  display: flex;
  justify-content: flex-end;
  padding-right: 10px;
}
.count {
  display: flex;
  align-items: center;
  padding-left: 16px;
}
.dwnBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 12px;
  gap: 8px;
  background: #ffffff;
  border: 1px solid #1c1c1c;
  border-radius: 100px;
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #1c1c1c;
  height: auto;
}
.purchaseHeading {
  font-size: 21px;
  font-weight: 600;
}
.searchPurSidebarHed {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: #000000;
}
.searchPurSidebarSubHed {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #333333;
  margin-bottom: 12px;
}
.searchPurSidebarSubDetail {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
  opacity: 0.5;
  margin-bottom: 12px;
}
.firstBox {
  background: #ffffff;
  border: 1px solid #dedede;
  border-radius: 4px;
  margin: 0px 24px 24px 0px;
  padding: 12px;
}
.dropDownBox {
  background-color: #ffffff !important;
  border: 1px solid #b1b1b1 !important;
  border-radius: 20px !important;
  height: 40px;
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #898989 !important;
}
.searchIcon {
  background: no-repeat 97% !important;
  background-image: url("../../../../assets/icons/search.svg") !important;
  padding-left: 20px !important;
  color: #000 !important;
}
.ml-5{
  margin-left: 5px;
}
.serPurBtn {
  border: 1px solid #1c1c1c;
  border-radius: 30px;
  height: 32px;
  width: auto;
  padding: 0 20px;
  float: right;
  margin-right: 5px;
  @media screen and (max-width: 999px) {
   font-size: 1.2vw  !important;
  }
}
.messageListView{
  padding-right: 10px;
}
.messageListViewWrapper{
  overflow: auto;
}
.completed {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #14ae23;
}
.pending {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #ffb600;
}
.tableStyle {
  background: rgba(222, 222, 222, 0.31);
  opacity: 0.8;
  border-radius: 8px;
  padding-top: 16px;
  padding-left: 8px;
  padding-bottom: 8px;
}
.tableBack {
  background: rgba(222, 222, 222, 0.31) !important;
}
.tableStyle table thead tr th {
  background: rgba(222, 222, 222, 0.31) !important;
}
.iconImg{
  vertical-align:middle;
}
.pl-0 {
  padding-left: 0px;
}
.pl-6 {
  padding-left: 6px;
}
.pl-9 {
  padding-left: 9px;
}
.pl-20 {
  padding-left: 20px;
}
.pb-16 {
  padding-bottom: 16px;
}
.pb-12 {
  padding-bottom: 12px;
}
.pb-10 {
  padding-bottom: 10px;
}
.pb-8 {
  padding-bottom: 8px;
}
.mb-7 {
  margin-bottom: 7px !important;
}
.pr-24 {
  padding-right: 24px;
}
.m-0 {
  margin: 0px;
}
.mr-26 {
  margin-right: 26px;
}
.textUnder:hover {
  text-decoration: underline !important;
}
.h-66 {
  height: 66vh;
}
.purchaseTable {
  thead {
    tr {
      th {
        .ant-table-column-title {
          font-family: IBM Plex Sans;
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 14px;
          color: #1c1c1c;
          text-align: center;
        }
      }
      th:nth-child(6) {
        .ant-table-column-title {
          text-align: right;
        }
      }
    }
  }
  tbody {
    tr {
      text-align: center;
      td:first-child {
        p {
          font-weight: 600;
          cursor: pointer;
          &:hover {
            text-decoration: underline;
          }
        }
      }
      td:nth-child(2) {
        p {
          cursor: pointer;
          &:hover {
            text-decoration: underline;
          }
        }
      }
      td:nth-child(6) {
        text-align: right !important;
      }
      td {
        p {
          padding-left: 16px;
          font-weight: 400;
          cursor: default;
          &:hover {
            text-decoration: none;
          }
        }
        div{
          padding-left: 16px;
        }
      }
    }
  }
}
.purchaseTable-preSales {
  thead {
    tr {
      th {
        .ant-table-column-title {
          font-family: IBM Plex Sans;
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 14px;
          color: #1c1c1c;
        }
      }
    }
  }
  tbody {
    tr {
      td:first-child {
        p {
          font-weight: 600;
          cursor: pointer;
          &:hover {
            text-decoration: underline;
          }
        }
      }
      td:nth-child(2) {
        p {
          cursor: pointer;
          &:hover {
            text-decoration: underline;
          }
        }
      }
      td {
        p {
          font-weight: 400;
          cursor: default;
          &:hover {
            text-decoration: none;
          }
        }
      }
    }
  }
}
.ant-table-ping-right:not(.ant-table-has-fix-right) .ant-table-container::after,
.ant-table-ping-left:not(.ant-table-has-fix-left) .ant-table-container::before {
  box-shadow: none !important;
}

.h-50 {
  height: 66vh !important;
  @media screen and ( max-height: 980px ){
    height: 64vh !important;
  }
  @media screen and ( max-height: 940px ){
    height: 60vh !important;
  }
  @media screen and ( max-height: 880px ){
    height: 56vh !important;
  }
  @media screen and ( max-height: 780px ){
    height: 50vh !important;
  }
  @media screen and ( max-height: 720px ){
    height: 48vh !important;
  }
}

.purchaseTableLoader {
  background-color: #fff;
}
.holdbtn {
  height: 35px;
  line-height: 14px !important;
}
.export-list-padding{
  padding-bottom: 12px;
  padding-left: 5px;
}
.shelves-edit-title-padding{
  padding-top: 20px;
}
.author-padding{
  margin-bottom: 0px;
}
.series-padding{
  margin-bottom: 0px;
}
.PurchaseTablePagination .ant-pagination{
  padding-top: 18px !important;
}

.purchase-add-to-shelf{
  justify-content: flex-start;
  width: 130% !important;
  .ant-btn-default:first-child{
   display :none;
  }
}
.add_to_shelf_text{
  text-align: center;
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 19px;
  margin-left: 39px;
    color: #1C1C1C;
  margin-top: 7px;
  margin-bottom: 28px;
}
.destination_shelf{
  display: flex;
  align-items: flex-end;
}
.selectShelfSelected{
  cursor: not-allowed;
}
.messageSearchlable{
  display: flex;
  position: relative;
  justify-content: center;
  padding:3px 8px 3px 1px;
  font-weight: 500;
  font-size: 15px;
}