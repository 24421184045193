.supportConatiner {
  margin-right: 27px;
  .coverImage {
    padding: 0 10%;
    background: black;
    margin-bottom: 0.5rem;
    img {
      width: 100%;
    }
  }

  .titleSupport {
    text-align: center;
  }

  .titleSupportSpan {
    text-align: center;
    p {
      font-size: 1.5em;
      font-weight: 500;
      margin-top: 0;
      margin-bottom: 0;
      font-family: 'IBM Plex Sans';
      color: black;
    }
  }
  .titleSupportLink_1 {
    margin-top: 1em;
    a {
      font-size: 20px;
      color: black;
      font-family: 'IBM Plex Sans';
      font-weight: 600;
      cursor: pointer;
    }
    :hover{
      text-decoration: underline;
    }
  }
  .titleSupportLink_2 {
    margin-top: 1em;
    margin-bottom: 1em !important;
    font-size: 1.5em;
    font-weight: 500;
    margin-bottom: 0;
    font-family: inherit;
    a {
      font-size: 18px;
      color: #0f84f1;
      font-family: 'IBM Plex Sans';
      font-weight: 600;
      cursor: pointer;
    }
    :hover{
      text-decoration: underline;
    }
  }
  .ant-divider-vertical {
    height: inherit;
    border-left: 3px solid rgb(0 0 0 / 42%);
  }
  .titleSupport-1 {
    h2 {
      font-size: 25px;
      font-family: "IBM Plex Sans";
      color: #00000094 !important;
      font-weight: 500;
      display: inline-flex;
      span {
        width: 40px;
        height: 40px;
        display: inline-flex;
      }
    }
  }
  .titleSupport_2 {
    h1 {
        font-family: 'IBM Plex Sans';
        font-size: 35px;
        font-weight: 500;
    }
  }
}
.search-results-list {
  height: 90vh !important;
}
.scrollOnBooksScreen {
  max-height: 90vh;
  overflow-y: auto !important;
}

.scrollOnBooksScreen1 {
  overflow: hidden;
  overflow-y: hidden;
}