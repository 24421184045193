.export-list-link {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  text-align: right;
  margin-right: 1%;
  text-decoration-line: underline;
  color: #000000;
  cursor: pointer;
  &:hover {
    text-decoration-line: underline;
    color: #000000;
  }
}
.edit-select-all {
  padding-right: 0 !important;
}
.exportColListCsv {
  height: 100%;
  padding-top: 6px;
  text-align: center;
}
.clearAudience {
  text-decoration: underline;
  display: inline-block;
  span {
    cursor: pointer;
    @media screen and (max-width: 1221px) {
      font-size: 0.89em;
    }
    @media screen and (min-width: 1221px) {
      font-size: 1em;
    }
  }
  @media screen and (max-width: 1201px) {
    margin-left: 0px;
  }
  @media screen and (min-width: 1202px) {
    margin-left: 5px;
  }
  @media screen and (min-width: 1210px) {
    margin-left: 10px;
  }
  @media screen and (min-width: 1250px) {
    margin-left: 15px;
  }
  @media screen and (min-width: 1260px) {
    margin-left: 20px;
  }
  @media screen and (min-width: 1400px) {
    margin-left: 10%;
  }
}
.audeinceCheckBoxes {
  display: inline-block;
  // margin-right: 15px;
  .ant-checkbox-wrapper {
    span {
      font-size: 0.86em;
    }
  }
}
.auidenceCheckBocGroup {
  padding: 0 0 5px 0;
  margin: 5px;
  text-align: center;
}
.clearAudience-title {
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  padding-top: 12px;
  padding-bottom: 15px;
  font-family: IBM Plex Sans;
}

.clearAudience-model .ant-modal-body {
  padding-bottom: 0px;
}
.export-list-collection {
  padding-left: 5px;
}
.change-audience-collection {
  padding-left: 4px;
  padding-bottom: 6px;
  margin-right: 0% !important;
}
.collection-dropdown .ant-dropdown {
  min-width: 161px !important;
}
.dropdown-shelves-title {
  display: flex;
  width: 100% !important;
  justify-content: space-between;
}
.shelves-live {
  background: #14ae23;
  border-radius: 2px;
  color: #ffffff;
  padding-left: 8px;
  padding-right: 8px;
  display: inline-block;
  font-size: 10px;
  padding-bottom: 1px;
  padding-top: 1px;
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 700;
  border-radius: 2px;
}

.shelves-draft {
  background: #ffde30;
  border-radius: 2px;
  color: #1c1c1c;
  padding-left: 8px;
  padding-right: 8px;
  display: inline-block;
  font-size: 10px;
  padding-bottom: 1px;
  padding-top: 1px;
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 700;
  border-radius: 2px;
}
.bookImage {
  width: 20px;
  height: 20px;
}
.shelves-title-ellipse {
  overflow: hidden;
  max-width: 16ch;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 600;
  color: #1c1c1c;
}
.shelve-title-in-myCollection {
  padding-right: 17px;
  padding-left: 0px !important;
  @media screen and (min-width: 1600px) {
    padding-right: 30px;
  }
}
.shelves-list-ellipse span {
  overflow: hidden;
  max-width: 30ch;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.status-span {
  margin-left: 20px;
}
.shelf-menu .ant-btn-icon-only {
  border: none !important;
}
.shelf-dropdown {
  width: 100% !important;
  border-bottom: 2px solid #1c1c1c;
}
.shelf-dropdown-name {
  display: flex;
  width: 100% !important;
}
.shelves-titles {
  margin-left: auto;
  margin-right: 15px;
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  color: #4f4949;
}
.copies-position {
  position: inherit;
  padding-left: 12px;
}

.coll-csv-export-row {
  height: 22px;

  .collection-export-list-link {
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    text-align: right;
    margin-right: 1%;
    text-decoration-line: underline;
    color: #000000;
    cursor: rgb(180, 45, 45) r;
    &:hover {
      text-decoration-line: underline;
      color: #000000;
    }
    padding: 0px;
    bottom: 18px;
    position: absolute;
    background: none;
    display: inline-flex;
    border: none;
  }
}
.not-allowed{
  cursor: not-allowed !important;
}