.scrollContent {
  max-height: 90vh;
  overflow-y: scroll;
  padding-right: 20px;
}
.scrollOnBooksScreen{
  max-height: 90vh;
  overflow-y: scroll;
  padding-right: 20px;
  @media screen and (max-height: 995px) {
    max-height: 87vh !important;
  }
  @media screen and (max-height: 847px) {
    max-height: 80vh !important;
  }
  @media screen and (max-height: 770px)  {
    max-height: 75vh !important;
  }
}
.scrollStyle::-webkit-scrollbar {
  width: 10px;
  background-color: #f3f3f3;
}
  
  /* Track */
.scrollStyle::-webkit-scrollbar-track {
  border-radius: 10px;
}

.scrollStyle{
  scrollbar-width: thin;
  scrollbar-color: #ccc transparent;
}
   
  /* Handle */
.scrollStyle::-webkit-scrollbar-thumb {
  background: #ccc; 
  border-radius: 4px;
}
  
  /* Handle on hover */
.scrollStyle::-webkit-scrollbar-thumb:hover {
  background: #999; 
}