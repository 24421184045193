.insightsScreen {
  .graph-row-style {
    // background: #83727230;
    background: white;
    padding-left: 20px !important;
  }
  .graphRow{
    border-bottom: 1px solid #d9d9d9;
    border-top: 1px solid #d9d9d9;
  }
  .graphList {
    padding-bottom: 18vh;
    max-height: 95vh;
    overflow: auto;
  }
  .graphToolTipCustom {
    padding: 0px 7px;
    height: 2rem;
    width: 100%;
    background-color: white;
    color: #fff;
    display: flex;
    color: #000000;
    justify-content: center;
    align-items: center;
    border-radius: 3.5px;
  }
  .graphToolTipCustom:after {
    content: "";
    position: absolute;
    border-left: 7.5px solid rgba(0, 0, 0, 0);
    border-right: 7.5px solid rgba(0, 0, 0, 0);
    border-bottom: 7.5px solid rgba(0, 0, 0, 0);
    border-top: 7.5px solid white;
    display: inline-block;
    height: 15px;
    top: 2.6em;
  }

  .graphRowDetails {
    .position-relative {
      position: relative !important;
    }
    .pr-15 {
      padding-right: 15px !important;
    }
    background: white;
    margin: 15px 0;
    .graphTitle {
      font-size: 2vw;
      font-weight: 500;
    }
    .graphBox {
      background: white;
      margin-bottom: 5px;
      margin-top: 5px;
      min-height: 200px !important;
      min-width: 200px !important;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      .donout-chart {
        width: 200px !important;
        height: 200px !important;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        @media only screen and (max-width: 992px) {
          width: 180px !important;
          height: 180px !important;
        }
        .center-div {
          height: 100%;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
    .graphContent {
      position: relative;
      padding: 10px 20px;
      width: 100%;
      height: 100%;
      .graph-heading {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        padding: 0px 20px 0px 10px;
      }
      .time-stamp-heading {
        font-weight: 500;
      }
      .graphContentTitle {
        font-weight: bold;
        font-size: 1.3rem;
        margin-bottom: 10px;
      }
      .bannerDays {
        position: absolute;
        max-width: 200px;
        // background: white;
        width: 100%;
        // border: 1px solid black;
        text-align: center;
        padding: 5px;
        font-weight: 500;
        border-radius: 5px;
        color: black;
        font-size: 16px;
        right: 10px;
        top: 0;
      }
      .table {
        padding-bottom: 10px;
        padding-left: 10px;
        background: white;
        padding-right: 10px;

        tr {
          td:first-child {
            width: 10%;
          }
          td:nth-child(2) {
            width: 25%;
          }
          td:nth-child(3) {
            width: 25%;
          }
          td:nth-child(4) {
            width: 15%;
          }
          td:nth-child(5) {
            width: 15%;
          }

          // th:first-child {
          //   width: 10%;
          // }
          // th:nth-child(2) {
          //   width: 25%;
          // }
          // th:nth-child(3) {
          //   width: 25%;
          // }
          // th:nth-child(4) {
          //   width: 15%;
          // }
          // th:nth-child(5) {
          //   width: 15%;
          // }
        }
      }
      table {
        width: 100%;
        background: white;
      }
      table,
      td {
        font-weight: bold;
        border: 1px solid grey;
        text-align: center;
      }
      table,
      th {
        border: none;
        height: 50px;
        span {
          display: inline-block;
        }
        .colorCircle {
          div {
            border-radius: 50%;
            width: 20px;
            height: 15px;
            position: relative;
            top: 2px;
            margin-right: 3px;
            border: 1px solid #9292b5bd;
          }
        }
      }
    }
  }
}
.head-container {
  padding-right: 24px;
}
.edit-rules-disable {
  cursor: not-allowed !important;
}
.allRulesCard {
  margin-top: -20px;
}
.parentAutocartTab > .ant-tabs-nav {
  display: none;
}
.cart-label {
  text-align: center;
}
.cart-label-red {
  color: rgba(255, 0, 0, 0.937);
}
.cart-label-green {
  color: rgb(0, 128, 0);
}
.commonsettingSave {
  position: relative;
  button {
    position: absolute;
    width: 10%;
    height: 40px;
    right: 40px;
    top: -5px;
    z-index: 9;
  }
}
.page-title {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  color: #4f4949;
  margin-bottom: 0px;
}
.new-cart {
  height: 40px;
}
.cartList {
  padding-bottom: 15vh;
  max-height: 80vh;
}
.cart-tabs {
  .ant-tabs-tab-btn {
    color: #000000 !important;
    font-size: 14px;
    line-height: 18px;
    font-weight: 600;
    opacity: 0.5;
  }
  .ant-tabs-tab-active .ant-tabs-tab-btn {
    font-weight: 600;
    opacity: 1 !important;
  }
  .ant-tabs-ink-bar {
    background: #000000 !important;
  }
  .ant-tabs-content-holder {
    width: 100% !important;
  }
}
.cart-tabs-settings {
  width: 100%;
  .ant-tabs-tab-btn {
    color: #000000 !important;
    font-size: 14px;
    line-height: 18px;
    font-weight: 600;
    opacity: 0.5;
  }
  .ant-tabs-tab-active .ant-tabs-tab-btn {
    font-weight: 600;
    opacity: 1 !important;
  }
  .ant-tabs-ink-bar {
    background: #000000 !important;
  }
  .ant-tabs-content-holder {
    width: 100% !important;
  }
}
.newCart-modal .ant-modal-footer {
  display: none;
}
.newCart-modal {
  .ant-modal-body {
    padding: 50px 90px;
  }
  .modal-title {
    justify-content: center;
    padding-bottom: 52px;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    display: flex;
    align-items: center;
    color: #1c1c1c;
  }
  .ant-checkbox-inner {
    padding-left: 2px;
    height: 30px !important;
    width: 30px !important;
  }
  .optional-text {
    padding-left: 15px;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    color: #333333;
  }
  .new-modal-input {
    height: 38px !important;
    border: 1px solid #dedede;
    box-sizing: border-box;
    border-radius: 19px;
    margin-bottom: 12px;
  }
  .new-cart-checkbox {
    display: flex;
    padding-top: 18px;
    padding-bottom: 52px;
    align-items: center;
    .ant-checkbox-inner::after {
      position: absolute;
      display: table;
      border: 1px solid #ffff;
      border-top: 0;
      border-left: 0;
      transform: rotate(42deg) scale(2) translate(-16%, -50%);
      opacity: 1;
      transition: all 0.1s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
      content: " ";
    }
  }
}
.delete-cart {
  .ant-modal-body {
    padding-top: 50px;
    padding-bottom: 22px;
  }
  .ant-modal-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-top: none;
    padding-bottom: 50px;
  }
  .delete-message {
    font-weight: normal;
    font-size: 20px;
    line-height: 28px;
    align-items: center;
    text-align: center;
    color: #000000;
  }
  .confirm-button {
    width: 240px;
    height: 40px;
  }
  .cancel-button {
    margin-top: 12px;
    width: 240px;
    height: 40px;
  }
}
.slider-footer {
  position: absolute;
  padding-top: 12px;
  bottom: 23px;
  right: 24px;
  left: 24px;
  background: #ffffff;
  button {
    height: 40px;
  }
}
.edit-rules {
  cursor: pointer;
  margin-right: 18px;
  margin-bottom: 0px;
}

.autoCarts-note {
  border-radius: 8px;
  align-items: center;
  padding-left: 16px;
  margin-bottom: 20px;
  p {
    color: #737373;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 0px;
  }
}
.ant-form-item {
  margin-bottom: 0px !important;
}
.cart-text {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #b1b1b1;
}
.spendLimitText {
  text-align: center;
}
.amountSpent {
  display: flex;
  justify-content: center;
}
.alertWarningIcon{
  font-size: 21px;
  margin-right: 5px;
}
.newLine {
  white-space: pre-line;
  font-family: IBM Plex Sans;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 120% !important;
  margin-bottom: 15px !important;
  color: #000000;
  margin-bottom: 0px !important;
}
@media only screen and (max-width: 768px) {
  .newLine {
    white-space: pre-line;
    font-family: IBM Plex Sans;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 12px !important;
    line-height: 120% !important;
    margin-bottom: 15px !important;
    color: #000000;
  }
}
.messHed {
  font-family: IBM Plex Sans;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 18px !important;
  color: #000000;
}
.messLine {
  font-family: IBM Plex Sans;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 18px !important;
}
.purLimBox {
  flex-direction: column;
}
.purLimRow {
  display: flex;
  justify-content: center;
  line-height: 18px !important;
}
.purLimRowHed {
  font-family: IBM Plex Sans;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 18px !important;
  color: #000000;
  padding-right: 10px;
}
.pb-10 {
  padding-bottom: 10px;
}
.pt-10 {
  padding-top: 10px;
}
.cartCancelBtn {
  margin-top: 25px;
  margin-left: 138px;
  border: none;
  background-color: rgb(0 0 0 / 0.01%);
  color: #ee0000;
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  cursor: pointer;
}
.cartRowGap {
  @media screen and (max-width: 767px) {
    row-gap: 5px !important;
  }
}

.shelfName {
  cursor: pointer;
}
.renderLoading {
  position: absolute;
  top: -5px;
  right: 2px;
}
.cartModelNameField {
  cursor: not-allowed !important;
}
.title-align {
  display: grid;
  align-items: center;
}

.paginationAlign {
  display: flex;
  justify-content: flex-end;
  padding-right: 50px;
}
.bottom-pagination-tag {
  width: 100%;
}
// .detailViewHeight{
//     height: 86vh !important;
//   @media screen and ( max-height: 980px ){
//     height: 85vh !important;
//   }
//   @media screen and ( max-height: 940px ){
//     height: 82vh !important;
//   }
//   @media screen and ( max-height: 880px ){
//     height: 82vh !important;
//   }
//   @media screen and ( max-height: 780px ){
//     height: 80vh !important;
//   }
//   @media screen and ( max-height: 720px ){
//     height: 78vh !important;
//   }
// }
.detailViewHeight {
  height: 100vh;
}
.cartDetailFooter {
  position: inherit !important;
  justify-content: flex-end;
}
.hideDetailFooter {
  display: none;
}
.cart_title_padding {
  padding-top: 10px;
}

.activity-container {
  .activity-bar-divider {
    display: flex;
    justify-content: center;
    height: auto;
    .verticalDivider {
      height: -webkit-fill-available;
      border-left: 1px solid #d9d9d9!important
    }
  }
  .no-wrap {
    white-space: nowrap;
  }
  .position-relative {
    position: relative !important;
  }
  .pr-15 {
    padding-right: 15px !important;
  }
  padding: 0px 0px 0px 20px;
  height: 120px;
  background: inherit;
  h1 {
    font-weight: bold;
    margin-bottom: 5px;
  }
  .activity-stats-left {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    div {
      text-align: center;
    }
    h1 {
      font-size: 1.7rem;
    }
    p {
      color: black;
      font-weight: 600;
      font-size: 1rem;
      margin: 0px;
    }
    .arrow-up {
      color: green;
      font-weight: bold;
    }
    .arrow-down {
      color: red;
      font-weight: bold;
    }
  }
  .activity-stats-right {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    div {
      text-align: center;
    }
    h1 {
      font-size: 1.7rem;
    }
    p {
      color: black;
      font-weight: 600;
      font-size: 1rem;
      margin: 0px;
    }
    .arrow-up {
      color: green;
      font-weight: bold;
    }
    .arrow-down {
      color: red;
      font-weight: bold;
    }
  }
}
.reports-info-icon {
  // position: absolute;
  // top: 0;
  // right: 0;
}
.no-data-ui {
  .heading {
    font-size: 20px;
    font-family: Arial, Helvetica, sans-serif;
    color: grey;
    font-weight: 500;
    margin-bottom: 0px;
    @media screen and (max-width: 992px) {
      font-size: 15px;
    }
  }
  .total-count {
    font-size: 30px;
    text-align: center;
    font-weight: bolder;
    margin-bottom: 0px;
    @media screen and (max-width: 992px) {
      font-size: 24px;
    }
  }
}
