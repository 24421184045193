.group-Tiles {
  border-radius: 8px;
  background: transparent;
  cursor: pointer;
  width: 16vh;
  min-width: 144px;
  .ant-card-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    padding: 0px;
    background: transparent;
  }
}
.group-cover {
  padding: 12px;
  height: 16vh;
  min-height: 144px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 8px;
  overflow: hidden;
  .title {
    font-family:IBM Plex Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    color: #ffffff;
    margin-bottom: 0 !important;
  }
}
.group-title {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  padding-top: 12px;
  color: #000000;
}
