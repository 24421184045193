.messageStyle {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin-bottom: 12px;
}

.messBox {
  padding: 24px 15px 21px 19px;
}

.messBoxHed {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  color: #000000;
  padding-bottom: 4px;
}

.messBoxText {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
  padding-bottom: 11px;
}

.messWhen {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #4F4949;
  padding-top: 11px;
  padding-bottom: 12px;
}

.messWhenClr {
  label {
    color: #B1B1B1;
    padding-right: 3px;
    font-size: 13px;
  }
}

.messWhenCreated {
  color: #B1B1B1 !important;
}

.messBoxRight {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.textRight {
  text-align: right;
  padding: 0px;
}

.deleBtn {
  margin-left: 25px;
}

.updtBtn {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  text-decoration-line: underline;
  color: #1C1C1C;
}

.messTag {
  background: #14AE23;
  border-radius: 100px;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 16px;
  text-transform: uppercase;
  color: #FFFFFF;
  padding: 2px 9px;
  margin-right: 4px;
}

.messTagBanner {
  background: #FFB600;
  border-radius: 100px;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 16px;
  text-transform: uppercase;
  color: #FFFFFF;
  padding: 2px 9px;
  margin-right: 4px;
}

.delHed {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #898989;
}

.deliveryPosition {
  display: flex;
  justify-content: flex-end;
}

.scrollAuto {
  overflow-y: auto !important;
}

.scrollOnMessScreen {
  max-height: 85vh;
}

.mt-20 {
  margin-top: 20px;
}

.m-0 {
  margin: 0px !important;
}

.pr-24 {
  padding-right: 24px;
}

.h-81 {
  height: 81vh;
}

.bolding {
  font-weight: bold;
}

.coloring {
  color: #000000 !important;
}

.padding {
  padding-left: 4%;
  padding-top: 4%;
}

.messagebtn {
  cursor: not-allowed;
}

.message-screen {
  .ant-tabs-extra-content {
    position: absolute;
    left: 200px;
    width: 100%;

    @media screen and (max-width: 1000px) {
      left: 180px;
    }

    @media screen and (max-width: 880px) {
      left: 160px;
    }

    .center-align {
      display: flex;
      align-items: center;
    }
  }

  .message-dropdown {
    border: 1px solid rgba(115, 115, 115, 0.4);
    border-radius: 100px;

    .ant-select-selection-item {
      @media screen and (max-width: 850px) {
        font-size: 12px;
      }
    }

    .ant-select-selector {
      height: 28px;
    }
  }

  .datepicker-container {
    @media screen and (max-width: 850px) {
      width: 190px;
    }

    .message-datepicker {
      border: 1px solid rgba(115, 115, 115, 0.4);
      border-radius: 100px;
      padding-left: 20px;
      max-width: 220px;
      height: 28px;
      width: 100%;

      input {
        @media screen and (max-width: 850px) {
          font-size: 12px;
          text-align: center;
        }
      }

      @media screen and (max-width: 850px) {
        padding-left: 10px;
      }
    }
  }

  .msg-search-btn {
    @media screen and (max-width: 850px) {
      font-size: 12px;
    }
  }

  .pr {
    padding-right: 20px;

    @media screen and (max-width: 1000px) {
      padding-right: 10px;
    }

    @media screen and (max-width: 880px) {
      padding-right: 6px;
    }
  }
}