.book-details{
    padding-bottom: 2vh;
}
.selected-book-title{
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
}
.selected-book-cover{
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 330px;
}
.book-ellipsis{
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.selected-book-label{
    height: 24px;
    width:24px;
    position: absolute;
    right: 8px;
    top: 8px;
}
.details-container{
    padding-top: 24px;
    padding-right: 40px;
}
.extra-details-title{
    font-family: IBM Plex Sans;
font-style: normal;
font-weight: bold;
font-size: 16px;
line-height: 24px;
color: #333333;
}

.extra-details{
    padding-top: 12px;
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: #000000;
    margin-bottom: 0px;
}
.button-container{
    padding-top: 24px;
}

.genre{
    padding-top: 8px;
}
.details{
    padding-top: 15px;
}
.infoCart-icon{
    padding-left: 2px;
    padding-bottom: 5px;
    cursor: pointer;
}
.reviews{
    height: 66px;
    background: #C4C4C4;
    opacity: 0.2;
}
.selectedBookTitleCover div:last-of-type {
    padding-bottom: 10px;
}
.bookDetailsCover {
    margin-top: 5px;
}

.book-author-label{
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
}
.book-author-value {
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    cursor: pointer;
    &:hover{
        text-decoration: underline;
    }
}
.book-series-family {
    font-family: sans-serif;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    color: rgba(0, 0, 0, 0.85);
}