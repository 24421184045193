.sideMenu{
    bottom: 0px;
    left: 0px;
    position: absolute;
    top: 0px;
    flex-grow: 0;
    flex-shrink: 0;
    min-width: 100px;
    // max-width: 260px;
    display: flex;
    border-right: #e9e9e9 1px solid;
    flex-direction: row;
    z-index: 2;
    will-change: width;
    transition: width 0ms cubic-bezier(0.1, 0, 0, 1) 0s;
    @media screen and (max-height: 1090px) {
      bottom: -10px !important;
    }
}
.nav-collapse-arrow{
  position: absolute;
  top: 2.3%;
  right: -10px;
  z-index: 9;
  width: 25px;
  height: 25px;
  border: 1px solid #1c1c1c29;
  padding: 0;
  border-radius: 50px;
  background: white;
  text-align: center;
  cursor: pointer;
  span{
    color: #1c1c1c70;
    font-weight: bolder;
  }
}
.nav-collapse-side-arrow{
  border: 1px solid red;
  position: absolute;
  top: 5.2%;
  right: -10px;
  z-index: 9;
  width: 22px;
  border: 1px solid #1c1c1c29;
  padding: 0;
  border-radius: 50px;
  background: white;
  text-align: center;
  cursor: pointer;
  span{
    color: #1c1c1c70;
    font-weight: bolder;
  }
}
.app-sidebar-resizer {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 6px;
    justify-self: flex-end;
    cursor: col-resize;
    resize: horizontal;
    &:hover{
        width: 6px;
        background: #c1c3c5b4;
        border-radius: 10px;
    }
  } 
  .content-container{
    flex: 1 1 auto;
    width: 0;
    will-change: padding-left;
    transition: padding-left 0ms cubic-bezier(0.1, 0, 0, 1) 0s;
  }

  .longName{
    display: flex;
    justify-content: center;
    margin-top: 3px;
    span:first-child {
      font-family: IBM Plex Sans;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: #4F4949;
      font-style: normal;
      border-radius: 20px;
      padding-left: 5px;
      padding-right: 5px;
      display: flex;
      justify-content: center;
    }
  }

  .headerLib {
    height: 70px !important;
  }

  .libRow {
    margin-bottom: -6px;
  }
