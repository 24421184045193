.head-container {
  padding-bottom: 14px;
}
.editorButton-List{
  .ant-dropdown-menu{
    min-width: 250px !important;
  }
}
.ant-select-tree-title{
  span{
    font-size: 12px;
    .cloud-link-info-icon{
      padding-bottom: 3px;
      width: 2.5%;
    }
  }
}
.edit-dropdown{
  padding: 4px 4px !important;
li:first-child{
  border-bottom: 1px solid #80808026 !important;
}
}
.round-btn{
  width: 32px;
  border-radius: 50% !important;
}
.page-title {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  color: #4f4949;
  margin-bottom: 0px;
}
.search-tabs {
  .ant-tabs-nav-operations{
    display: none !important;
  }
  .ant-tabs-nav{
    .ant-tabs-nav-wrap{
      flex: none;
    }
    .ant-tabs-extra-content{
      flex:auto;
    }
  }

  .ant-tabs-tab-btn {
    color: #000000 !important;
    font-size: 14px;
    line-height: 18px;
    font-weight: 600;
    opacity: 0.5;
  }
  .ant-tabs-tab-active .ant-tabs-tab-btn {
    font-weight: 600;
    opacity: 1 !important;
  }
  .ant-tabs-ink-bar {
    background: #000000 !important;
  }
  .ant-tabs-content-holder {
    width: 100% !important;
  }
  .ant-tabs-nav {
    margin: 0px 0px 0px 0px !important;
  }
}
.isbn-container {
  padding: 20px;
  background: #f3f3f3;
}
.isbn-input-col {
  padding: 16px;
  background:#FFFFFF;
}
@media only screen and (max-width: 1024px) {

}
.uploadButton {
  height: 40px;
}
.ant-upload.ant-upload-drag {
  border: none !important;
  justify-content: center;
}
.isbn-title {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  color: #000000;
  margin-bottom: 16px !important;
}
.button-container {
  padding-top: 16px;
}
.ant-upload-btn {
  display: block !important;
}
.results-text{
  .label{
    display: inline-block;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 100%;
    color: #4F4949;
  }
.searchString{
  display: inline-block;
  cursor: pointer;
  .textStyle{
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #4F4949;
    div{
      p{
        margin-bottom: 0 !important;
        display: inline-block;
        span{
          font-weight: bold;
         }
         .labelTerm{
          color: #4F4949 !important;
          font-weight: 400 !important;
         }
      }
    }
  }
}
}
.results-title{
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  color: #4F4949;
}
.search-overlay{
  height: 32px;
  background: #1C1C1C;
  border-radius: 100px;
  font-weight: bolder;
}
.saved-search-header{
  padding-top: 12px;
}
.csv-label{
  /* Auto Layout */

display: flex;
flex-direction: column;
align-items: center;
padding: 16px;

position: static;
width: 470px;
height: 172px;
left: 496px;
top: 20px;

/* White / White */

background: #FFFFFF;
border-radius: 8px;

/* Inside Auto Layout */

flex: none;
order: 1;
flex-grow: 0;
margin: 0px 20px;


}
.csv-input{
  width: 100%;
  height: 100%;
  opacity: 0;
}
.react-csv-input{
  display: inline-block;
  height: 38% !important;
  background-image: url(./../../../../assets/images/uploadLogo.png);
  background-repeat: no-repeat;
  background-position-x: center;
}
.search-results{
  .ant-spin-nested-loading > div > .ant-spin{
    margin-top: 10% !important;
  }
}
.search-results-list {
  .ant-spin-nested-loading > div > .ant-spin{
    margin-top: 10% !important;
  }
  height: 70vh;
  padding-right: 12px;
  padding-bottom: 5vh;
}
.saved-results-list {
  .ant-spin-nested-loading > div > .ant-spin{
    margin-top: 10% !important;
  }
  height: 62vh;
  padding-right: 12px;
  padding-bottom: 5vh;
}
.StringtooltipStyle{
  .ant-tooltip-inner{
    min-width: 30em !important;
    width: 100% !important;
  }
}
.searchTitleStyle{
  text-align: center;
  display: flex;
  justify-content: center;
  padding-top:10PX ;
  font-family: IBM Plex Sans !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 18px !important;
  color: #737373 !important;
}
.searchTitleCountStyle{
  display: flex;
  align-items: center;
}

.clear-isbn-button {
  background: #ffffff !important;
  height: 40px;
  color: #000000;
  &:hover {
    background: #ffffff !important;
  }
}
.csv-text {
  color: rgb(0 0 0 / 85%) !important;
  background-color: #fff;
  display: flex;
  justify-content: center;
  font-weight: 600;
}
.csv-up-height{
  min-width: 370px;
  padding-bottom: 40px;
  text-align: center;
  h1{
    font-weight: bold;
  }
  p{
    color: #737373;
  }
}
.pr-5 {
  padding-right: 5px;
}
.ml-15 {
  margin-left: 15px;
}
.saveAud {
  height: 32px;
}
.audHed {
  font-weight: 600 !important;
}
.export-list-search{
  padding-bottom: 12px;
  padding-left: 2px;
}
.isbnSearchScroll::-webkit-scrollbar {
  width: 18px;
  background-color: transparent !important;
  }
.isbnSearchScroll::-webkit-scrollbar-thumb {
  border-radius: 15px;
  border: 5px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  &:hover{
    border-radius: 15px;
    border: 5px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
  }   
}

.searchResultScroll{
  height: 58vh;
  @media screen and ( min-height: 850px ){
    height: 60vh;
    }
  @media screen and ( min-height: 950px ){
    height: 62vh;
    }
    @media screen and ( min-height: 1050px ){
      height: 65vh;
      }
}

.collectionResultScroll {
  height: 52vh;
  @media screen and ( min-height: 820px ){
    height: 56vh;
    }
  @media screen and ( min-height: 870px ){
    height: 58vh;
    }
  @media screen and ( min-height: 950px ){
    height: 62vh;
    }
  @media screen and ( min-height: 1050px ){
    height: 65vh;
    }
}

.search-divider {
  margin-top: 15px;
}

.search-results .pageStyle {
  padding-top: 15px;
}
.unmatched_isbn__detail{
  margin-bottom: 12px;
  background-repeat: no-repeat;
  border-radius: 20px;
  padding: 5px 35px 5px 20px;
  overflow-y: auto;
  height: 150px !important;
  margin-top: 12px;
  &:focus {
    border-radius: 20px;
    background-position: 95% 8px;
  }
  }
 
.unmatched_isbn_title{
  margin-right: 20px;
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
}
.unmatched_isbn_export_list{
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  text-decoration-line: underline;
  color: #000000;
  cursor: pointer;
  &:hover{
      text-decoration-line: underline;
      color: #000000;
  }
}
.unmatched_isbn_scroll{
  overflow-y: auto;
  max-height: 75vh; 
  padding-right: 20px; 
  @media screen and ( max-width: 991px ){
    padding-bottom: 80px;
  } 
}
@media screen and ( max-width: 991px ){
  .search_by_isbn_order{
    order:2;
  }
  .isbn_csv_file_order{
    order:1;
  }
}
.selectAllWrapper {
  display: inline-block;
}
.selectAllColWrapper {
  padding-top: 12px;
  padding-left: 2%;
  .selectAllWrapper .ant-checkbox-wrapper {
    margin-right: 15px;
  }
}

@media screen and ( min-width: 1420px ){
  .selectAllColWrapper {
    padding-left: 5.4%;
  }
}

@media screen and ( min-width: 1550px ){
  .selectAllColWrapper {
    padding-left: 4.9%;
  }
}

@media screen and ( min-width: 1650px ){
  .selectAllColWrapper {
    padding-left: 4.4%;
  }
}

@media screen and ( min-width: 1850px ){
  .selectAllColWrapper {
    padding-left: 3.7%;
  }
}
.total_not_matched{
  font-weight: bolder;
}

.search-export-list-link{
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  text-align: right;
  margin-right: 1%;
  text-decoration-line: underline;
  color: #000000;
  cursor: pointer;
  &:hover{
      text-decoration-line: underline;
      color: #000000;
  }
  padding: 0px;
  bottom: 14px;
  position: absolute;
  background: none;
  display: inline-flex;
  border: none;
}