.dropdown {
    background: #ffffff;
    border: 1px solid #EDEDED !important;
    box-sizing: border-box !important;
    box-shadow: 0px 54px 29px rgba(0, 0, 0, 0.02),
      0px 27.0264px 14.5142px rgba(0, 0, 0, 0.0152067),
      0px 16.2794px 8.74265px rgba(0, 0, 0, 0.0130318),
      0px 10.4327px 5.60275px rgba(0, 0, 0, 0.0114179),
      0px 6.76164px 3.63125px rgba(0, 0, 0, 0.01),
      0px 4.25637px 2.28583px rgba(0, 0, 0, 0.0085821),
      0px 2.44555px 1.31335px rgba(0, 0, 0, 0.00696822),
      0px 1.07635px 0.57804px rgba(0, 0, 0, 0.00479335) !important;
      border-radius: 10px !important;
    font-size: 14px;
}
.options {
    padding-top: 2px !important;
    border-left: 2px solid transparent;
    text-align: left;
    &:hover {
      border-left: 2px solid red;
      background: #f0f0f0 !important;
    }
  }
  
.loginDropdown {
  border-left: 2px solid transparent;
  &:hover {
    border-left: 2px solid red;
    background: #f0f0f0;
    font-weight: 400;
  }
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  background: #ffffff;
  border: 1px solid #ededed;
  box-sizing: border-box;
  border-radius: 10px;
  text-align: justify;
  font-family: 'IBM Plex Sans'; 
  font-weight: normal;
  color: #1c1c1c;
  cursor: pointer;
  caret-color: red;
  display: flex;
  align-items: center;
  padding-top: 0px !important;
  &:hover {
    cursor: pointer;
    border: 1px solid #cbcbcb;
  }
  &:active {
    border: 1px solid #ededed;
  }
  &:focus {
    border-color: 1px solid #55c1f6 !important;
  }
}
.ant-select-single .ant-select-selector:focus {
  border-color: 1px solid #55c1f6 !important;
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  background-color: transparent;
}
.dropdown-header .ant-select .ant-select-single .ant-select-show-arrow{
  width: 100% !important;
}
.option-desc{
  font-family: IBM Plex Sans;
font-style: normal;
font-weight: normal;
font-size: 12px;
line-height: 16px;
color: #B1B1B1;
}
.ant-select-selection-item > br{
  display: none;
}
.ant-select-selection-item{
  .option-desc{
    visibility: hidden;
  }
}