.ant-pagination-options{
    display: none;
}
.ant-pagination-item {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    min-width: 30px;
    height: 30px;
    margin-right: 6px;
    font-family:IBM Plex Sans;
    line-height: 18px;
    text-align: center;
    vertical-align: middle;
    list-style: none;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    border: 0px;
    outline: 0;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    &:hover {
        border: 1px solid #737373 !important;
        box-sizing: border-box;
        border-radius: 6px !important;
        a{
            color: #333333 ;
        }
    }
}
.ant-pagination-item-active{
    background: #333333;
    border-radius: 6px;
    a{
        color: #FFFFFF;
    }
    &:hover {
        a{
            color: #FFFFFF !important;
        }
    }
}
.ant-pagination{
    text-align: center;
    padding-top: 40px;
}
.ant-pagination-item-link{
    border: 0px !important;
}