.hideShelvsTabs {
  .ant-tabs-nav-wrap {
    display: none !important;
  }
}
.shelvsListnewStructure {
  .newRowA {
    display: "inline-block";
    @media screen and (max-width: 1000px) {
      width: 40% !important;
    }
    @media screen and (max-width: 1190px) {
      width: 50%;
    }
    @media screen and (max-width: 1290px) {
      width: 65%;
    }
    @media screen and (min-width: 1292px) {
      width: 70% !important;
    }
  }
  .newRowB {
    display: "inline-block";
    @media screen and (max-width: 1000px) {
      width: 60% !important;
    }
    @media screen and (max-width: 1190px) {
      width: 50%;
    }
    @media screen and (max-width: 1290px) {
      width: 35%;
    }
    @media screen and (min-width: 1292px) {
      width: 30% !important;
    }
  }
}
.filters-contatiner-byCheckbox {
  .ant-checkbox-checked .ant-checkbox-inner::after {
    border: 3px solid #fff;
    left: 25.5% !important;
    border-top: 0;
    border-left: 0;
  }
  .ant-checkbox-checked {
    display: flex;
    position: relative;
    top: 5px;
  }
  .ant-checkbox-checked::after {
    border: 1px solid #1c1c1c !important;
  }
  .ant-checkbox-inner {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    width: 20px;
    height: 20px;
    direction: ltr;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    border-collapse: initial;
    transition: all 0.3s;
  }
  .ant-checkbox + span {
    font-size: 16px !important;
    font-weight: 800 !important;
  }
}
.shleves-header {
  position: relative;
  .shelves-header-buttons {
    position: absolute;
    top: -20px;
    right: 0;
    width: 30%;
    margin-right: 24px;
    .shelv-header-btnBox {
      .shelv-leftBtn {
        padding-right: 5px;
        button {
          background-color: transparent !important;
          border: 1px solid #1c1c1c !important;
          color: #1c1c1c !important;
          box-shadow: none !important;
        }
      }
      .shelv-rightBtn {
        padding-left: 5px;
        button {
          box-shadow: none !important;
        }
      }
    }
  }
}

.activeList {
  border: 2px solid grey !important;
  background: #80808036 !important;
}

.text-countClick {
  cursor: pointer;
}

.left_Arrow {
  position: absolute;
  top: 22%;
  width: 20px;
  left: -21px;
  font-size: 35px;
  background: #00000008;
  cursor: pointer;
  text-align: center;
}

.shelv-show-Btn {
  width: 47%;
  float: right;
  height: 26px;
  @media screen and (max-width: 770px) {
    width: 50% !important;
  }
  @media screen and (max-width: 980px) {
    width: 60%;
  }
  button {
    background: transparent !important;
    color: black;
    height: 32px;
    margin-top: 18px;
    box-shadow: 0px 0px 0px !important ;
    font-weight: 600;
    font-size: 11px;
    @media screen and (max-width: 980px) {
      font-size: 13px;
    }
    &:hover {
      background: transparent !important;
      box-shadow: 0px 0px 0px !important ;
    }
  }
}

.ant-list-items {
  margin-top: 26px;
  .ant-list-item {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 10px !important;
    margin-bottom: 12px;
    padding: 0px;
    .DragDroptoolTip {
      padding-top: 7px;
      font: caption;
      .ant-popover-inner-content {
        padding: 2px;
      }
    }
    .List-Card-Content {
      padding: 0px 10px 0px 12px;
      .card-titel {
        font-family: IBM Plex Sans;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 28px;
        color: #4f4949;
      }
      .card-header-button {
        .card-btn-row {
          display: flex;
          justify-content: flex-end;
        }
        .pink-btn {
          display: inline-block;
          margin-right: 10px;
          justify-content: center;
          align-items: center;
          font-family: IBM Plex Sans;
          font-style: normal;
          font-size: 10px;
          line-height: 16px;
          color: white;
          background: #ff8787;
          border-radius: 2px;
          border: none;
          width: 81px;
          height: 20px;
        }
        .green-btn {
          display: inline-block;
          margin-right: 10px;
          justify-content: center;
          align-items: center;
          font-family: IBM Plex Sans;
          font-style: normal;
          font-size: 10px;
          line-height: 16px;
          color: white;
          background: #14ae23;
          border-radius: 2px;
          border: none;
          width: 81px;
          height: 20px;
        }
        .blue-btn {
          display: inline-block;
          margin-right: 10px;
          justify-content: center;
          align-items: center;
          font-family: IBM Plex Sans;
          font-style: normal;
          font-size: 10px;
          line-height: 16px;
          color: white;
          background: #4c8bf5;
          border-radius: 2px;
          border: none;
          width: 81px;
          height: 20px;
        }
        .yellow-btn {
          background: #ffde30 !important;
          border-radius: 2px;
          font-family: IBM Plex Sans;
          font-style: normal;
          font-size: 10px;
          line-height: 16px;
          display: flex;
          align-items: center;
          text-align: center;
          text-transform: uppercase;
          color: #1c1c1c;
        }
        .edit-delet-btns {
          display: inline-block;
          .editBtn {
            display: inline-block;
            padding-left: 28px;
            cursor: pointer;
          }
          .deleteBtn {
            display: inline-block;
            padding-left: 28px;
            cursor: pointer;
          }
        }
        .btn-size-fixed {
          font-family: "IBM Plex Sans";
          font-style: normal;
          font-weight: 700;
          font-size: 11px;
          width: 81px;
          height: 25px;
          cursor: text;
        }
      }
      .card-info {
        .card-name-slip {
          font-family: IBM Plex Sans;
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 16px;
          color: #4f4949;
        }
        .card-name-decrip {
          font-family: IBM Plex Sans;
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 16px;
          color: #b1b1b1;
          padding-left: 5px;
        }
      }
      .card-books-container {
        padding-top: 15px;
        padding-bottom: 10px;
        padding-left: 11px;
        .card-books {
          img {
            padding: 0 3px;
            width: 96px;
            height: 150px;
            margin-bottom: 6px;
            margin-left: 5px;
          }
          img:nth-child(1) {
            padding-left: 0 !important;
          }
        }
        .text-count {
          font-family: IBM Plex Sans;
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 20px;
          color: #4f4949;
        }
      }
    }
  }
}
.shelvesModal {
  padding: 45px 24px 40px 24px !important;
}
.shelvesModal .ant-modal-footer {
  display: none;
}
.shelvesModal {
  .ant-modal-body {
    padding: 45px 24px 40px 24px !important;
  }
}
.shelvesTitle {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1c1c1c;
  padding-bottom: 35px;
}
.autoSelfCard {
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  border-radius: 10px;
  padding: 18px 24px 21px 24px;
  cursor: pointer;
}
.cardTitel {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  color: #4f4949;
  padding-bottom: 4px;
}
.cardDetail {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #4f4949;
  padding-top: 4px;
}
.mb-6 {
  margin-bottom: 6px;
}
.mb-40 {
  margin-bottom: 40px;
}
.mt-6 {
  margin-top: 6px;
}
.mt-40 {
  margin-top: 40px;
}
.autoShelvesModal {
  padding: 49px 90px 62px 90px !important;
}
.autoShelvesModal .ant-modal-footer {
  display: none;
}
.autoShelvesModal {
  .ant-modal-body {
    padding: 49px 90px 62px 90px !important;
  }
}
.autoShelvesTitle {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1c1c1c;
  padding-bottom: 37px;
}
.shelfTitle {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #4f4949;
  border: 1px solid #dedede;
  box-sizing: border-box;
  border-radius: 19px;
  padding: 9px 0px 9px 17px;
}
.shelfDescription {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #4f4949;
  border: 1px solid #dedede;
  box-sizing: border-box;
  border-radius: 19px;
  padding: 9px 0px 9px 17px;
}
.card-info-padding {
  margin-top: 5px;
  margin-bottom: 5px;
}
.books-shelves {
  cursor: url("../../../../assets/dummy/dragingCursor.png"), move;
}
.books-shelves .ant-list-item:last-child {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.manAutoShelve {
  cursor: pointer;
}
.List-Card-Content-noPopover {
  padding: 15px 10px 0px 12px !important;
}
.shelvesPopover {
  .ant-popover {
    left: 18px !important;
    top: -11px !important;
    padding-left: 0px;
  }
  .ant-popover-inner {
    font-family: IBM Plex Sans;
  }
  .ant-popover-inner-content {
    padding: 6px !important;
  }
}
.title-adjustment {
  display: flex;
  align-items: center;
}
.manualShelfBtn button {
  cursor: not-allowed;
}
.manualShelveError {
  color: #e00;
  font-weight: 400;
  font-family: IBM Plex Sans;
  font-style: normal;
  font-size: 0.75rem;
  line-height: 20px;
  padding-left: 15px;
}

.manualShelveField {
  border: 1px solid #ee0000;
  &:focus {
    border: 1px solid #ee0000 !important;
    box-shadow: none;
  }
}

.autoShelveModal {
  cursor: not-allowed;
}
.autoShelvedropdown {
  height: 38px !important;
  border: 1px solid #dedede;
  box-sizing: border-box;
  border-radius: 19px;
  margin-bottom: 12px;
  padding-top: 2px;
}
.autoShelves-divider {
  margin-top: 8px;
  margin-bottom: 0px;
}
.shelvesCancel {
  width: 100%;
  height: 30px;
  background: none;
  border: none;
  font-style: normal;
  font-weight: bold;
  line-height: 20px;
  text-align: center;
  color: #ee0000;
  cursor: pointer;
}

.createShelfHeadCol {
  text-align: end;
  margin-right: 20px;
  .blue-tag {
    background-color: #4c8bf5 !important;
    &:hover {
      background-color: #4c8bf5 !important;
    }
  }
  .yellow-tag {
    background-color: #ffde30 !important;
    color: #1c1c1c;
    &:hover {
      background-color: #ffde30 !important;
    }
  }
  .green-tag {
    background-color: #14ae23 !important;
    &:hover {
      background-color: #14ae23 !important;
    }
  }
  .pink-tag {
    background-color: #ff8787 !important;
    &:hover {
      background-color: #ff8787 !important;
    }
  }
  button {
    height: 25px;
    border-radius: 0px;
    border: none !important;
    box-shadow: none;
    &:hover {
      border-radius: 0px;
      border: none !important;
      box-shadow: none !important;
    }
  }
  button:first-of-type {
    margin-right: 10px;
  }
}
.refreshBtn {
  display: flex;
  align-items: center;
  margin-right: 16px;
  cursor: pointer;
  position: relative;
}
.syncMsgStyle {
  color: #004f70;
  font-size: 16px;
  font-weight: 500;
  position: absolute;
  right: 1px;
  width: 140px;
}
.syncIcon {
  font-size: large;
}
.card-author-label {
  label {
    line-height: 11px !important;
  }
}
.shelves-img-container {
  position: relative;
  display: inline-flex;
  cursor: pointer;
}
.shelve-img-transbox {
  position: absolute;
  opacity: 0.7;
  bottom: 44px;
  background-color: black;
  top: 110px;
  width: 91%;
  height: 40px;
  margin-left: 5px;
}
.shelve-img-price {
  font-size: 12px;
  font-weight: 900;
  color: #fafafa;
  padding-left: 16px;
}
.shelve-img-title {
  overflow: hidden;
  color: #fafafa;
  max-width: 30ch;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
  font-weight: 900;
  margin-bottom: 0px;
  padding-left: 3px;
}
