
.main-header{
  justify-content: flex-end;
  font-family: IBM Plex Sans;
  .ant-spin-nested-loading > div > .ant-spin{
    top: 50%;
  }
.notifyBtn{
      margin-left: 74px;
      display: block ruby;
      position: absolute;
      width: 300px;
      z-index: 9;
      @media only screen and (max-width: 1201px) {
        bottom: -50px
      }
      @media only screen and (min-width: 1201px) {
             margin-left: 100px;
         }
      @media only screen and (min-width: 1400px) {
          margin-left: 120px;
      }
      .button-type{
        cursor: pointer;
      }
    }
  }
  .webinarPopContent{
    max-width: 600px;
    width: 100%;
    .sesssions{
      padding-bottom: 10px;
      .Urllinks{
        color: #000000d9;
        text-decoration: underline;
      }
      .Urllinks:hover{
        background: #80808014;
      }
    }
    .webinar-label{
      font-weight: 600;
    }
   }
   
iframe{
  display: none !important;
}
.ant-layout-header {
  padding: 0px !important;
  background: transparent !important;
}
.logo {
  align-items: center;
  justify-content: center;
  padding-left: 20px;
}
.search-cloumn{
  display: flex;
  align-items: center;
  padding-top: 12px;
  padding-bottom: 12px;
}
.cartIcon {
  border: 1px solid rgba(115, 115, 115, 0.4);
}
.cart {
  font-family: IBM Plex Sans;
  padding-left: 20px;
  .cart-dropdown {
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    width: 80%;
  }
  .cart-menu {
    width: 100% !important;
  }
  .ant-btn-default {
    border: 1px solid rgba(115, 115, 115, 0.4);
    height: 40px !important;
    border-top-left-radius: 6px !important;
    border-bottom-left-radius: 6px !important;
    width: 23% !important;
  }
  .ant-dropdown-trigger {
    border-top-right-radius: 6px !important;
    border-bottom-right-radius: 6px !important;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    text-align: left;
    width: 77% !important;
    .downIcon {
      float: right;
      padding-left: 12px;
      padding-right: 12px;
    }
  }
  .ant-btn-icon-only {
    padding-top: 3px !important;
    padding-left: 10px !important;
  }
}

.dropDown-title {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #737373;
  width: fit-content;
  margin-bottom: 0px !important;
}
.dropDown-title-content {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #4f4949;
  margin-bottom: 2px;
}
.bellIcon {
  cursor: pointer;
}
.User {
  padding-top: 22px;
  font-family: IBM Plex Sans;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #4F4949;
  width: 100%;
  height: 50px;
  cursor: pointer;
  font-style: normal;
}
.header-dropdown {
  font-family: IBM Plex Sans;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  max-height: 300px;
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: #ccc transparent;
  &::-webkit-scrollbar {
    width: 5px;
  } 
 &::-webkit-scrollbar-track {
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: #ccc; 
    border-radius: 4px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #999; 
  }
}
.dropDown-content {
  font-family: IBM Plex Sans;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #000000;
  opacity: 0.8;
}
.dropDown-link {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  text-decoration-line: underline;
  color: #000000;
}
.userMenu {
  padding-left: 8px;
}
.user-menu {
  justify-content: end;
}

.sign-out {
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: black;
  opacity: 0.8;
}

.dropDown-link {
  p {
    font-family: IBM Plex Sans;
    margin-bottom: 0px;
  }
}

.bookCover {
  font-family: IBM Plex Sans;
  height: 26px;
  width: 28px;
}

.bookFormat {
  padding-left: 10px;
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.8);
}
.trigger{
position: absolute;
top:14px;
z-index:100;
will-change: left;
transition: left  100ms cubic-bezier(0.1, 0, 0, 1) 0s;
}
.open{
  left:-8px;
}
.closed{
  left:4px;
}
.legacy_cat_ui{
  height: 32px;
  border: 0.5px;
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  background: #2193b0;
  border-radius: 4px;
  padding: 0px 6px;
  color:#ffffff;
  margin-left: 10px !important;
  &:hover{
    color:#ffffff;
    cursor: pointer;
  }
}