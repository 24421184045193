.icon-container{
  padding-top: 24px;
  padding-left: 24px;
  padding-right: 24px;
}
.hideSideMenuSpotlight{
  display: none;
}
.sideNavLoader{
  .renderSideNavLoading{
    position: absolute;
    bottom: 4px;
    right: 10px;  
  }
}
.cloudIcon{
  height: 100%;
  width: 100%;
  max-height: 31px;
  max-width: 160px;
}
.settingsBtn{
  cursor: pointer;
}
.main-sidebar {
  overflow-y: auto;
  .ant-menu{
    background-color: #F3F3F3;
  }
  display: flex;
  flex-direction: column;
  color: #1c1c1c;
  height: 92%;
  font-family: IBM Plex Sans;
  justify-content: space-between;
  .ant-menu-item-group-title{
    font-weight: 600;
    font-size: 10px;
    line-height: 14px;
    color: #000000;
    opacity: 0.5;
  }
  .settings-button{
    display: flex;
    border: none;
    padding: 0px;
    padding-left: 27px;
    padding-bottom: 20px;
    background: transparent;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #1c1c1c;
    flex-flow: column;
    .settingsIcon{
      padding-right: 12px;
    }
  }
  .libraryName {
    padding-bottom: 15px;
    word-break: break-word;
    text-align: start;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #1c1c1c;
  }
}
.menu-item-row{
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 20px;
color: #1c1c1c;
a{
  color: #1c1c1c;
}
}
.menu-item-row:hover {
  background-color: rgba(255,255,255,0.2)!important;
  cursor: pointer;
  a{
    color: #1c1c1c;
  }
}
.libraryOptions {
  opacity: 0.3;
}
.ant-menu-item-selected{
  font-weight: 500;
  color:#5e5e5e !important;
  background: rgba(165, 165, 165, 0.3) !important;
}
.ant-menu-item::after {
  border-right: none !important;
}
.main-sidebar .menu-item-row:hover {
  background-color: #f0f0f0 !important;
  color:#1c1c1c;
}
.settingsText {
  padding-bottom: 0px !important;
  margin: 0px;
  width: 100%;
}
.settingsLink{
  padding-left: 0px !important;
  padding-top: 10px !important;
  padding-bottom: 20px !important;
  margin: 0px;
  width: 100%;
}
.settingLink {
  padding-left: 27px;
}
.showSetting {
  background: rgba(165, 165, 165, 0.3) !important;
}
.sideSetting {
  background: transparent !important;
}