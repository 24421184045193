.category-title{
  font-family: IBM Plex Sans;
font-style: normal;
font-weight: bold;
font-size: 24px;
line-height: 28px;
color: #1C1C1C;
}

.listBox{
  margin-bottom: 10%;
}

.tiles-container{
  padding-top: 19px;
}

.category {
  margin-top: 40px;
  justify-content: space-between;
}
.category-col{
  margin-top: 20px;
}

.view-all-link{
padding-left: 10px;
font-family: IBM Plex Sans;
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 18px;
text-align: right;
text-decoration-line: underline;
color: #1C1C1C;
cursor:pointer;
}

.groups {
  padding-bottom: 2vh;
}

.groups-container{
  height: 100vh;
  padding-top: 20px;

  .ant-spin-nested-loading {
    top: 40%;
  }
}
.groups-container-loading{
  .ant-spin-nested-loading {
    top: 0 !important;
  }
}

.spotLightDrawerWrapper{
  height: 100%;
}

.spotlightDrawerTitle{
  font-family: IBM Plex Sans;
font-style: normal;
font-weight: 700;
font-size: 22px;
line-height: 18px;
padding-bottom: 25px;
}
.spotLightDrawerWrapper .sub-drawer{
  height: 97%;
  overflow: auto;
}
.spotlightDrawerCancelBtn {
  font-family: IBM Plex Sans;
  font-style: normal;
  width:100%; 
  margin-right: 12px;
}
.spotlightDrawerSaveBtn {
  color: #FFFFFF;
  background-color: #1C1C1C !important;
  border: 1px solid #1C1C1C !important;
  width:48%; 
}
.spotlightDrawerCancelBtn.ant-btn{
  &:hover {
    color: #1C1C1C;
  }
}
.spotlightDrawerSaveBtn.ant-btn{
    &:hover {
      color: #FFFFFF;
      background-color: #1C1C1C !important;
      border: 1px solid #1C1C1C !important;
    }
  }
.spotlightDrawerTableTitle{
  font-family: IBM Plex Sans;
  font-style: normal;
  color: #1c1c1c; 
  font-weight: 700;
}
.spotlightDrawerTablePadding{
  margin-bottom: 18px;
}
.spotlightDrawerTableText{
  font-family: IBM Plex Sans;
  font-style: normal;
}
.spotlightDrawerTableDate{
  font-family: IBM Plex Sans;
  font-style: normal;
  margin-top: -2px;
}
.GroupNamepadding{
  padding-left: 28px;
}
.spotlightDrawerNewTag{
  font-family: IBM Plex Sans;
  font-style: normal;
  border-radius: 5px;
  border:1px solid black !important;
  border-color: #1c1c1c;
  color: #1c1c1c;
  font-weight: 500;   
  font-size: 11px;   
  margin-left: 17px;
  padding: 1px 2px 1px 2px;
}
// .CustomizeButtonPadding>div:first-child .ant-row{
//   margin-top: 0px;
// }
.spotLightDrawerCheckbox .ant-checkbox-checked .ant-checkbox-inner{
  background-color: grey !important;
  border-color: grey;
}
.spotLightDrawerCheckbox span{
  overflow: hidden;
  max-width: 20ch;
  text-overflow: ellipsis;
  white-space: nowrap;
}