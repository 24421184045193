.deactive-model {
    .ant-modal {
        width: 540px !important;
    }
    .ant-modal-body {
        padding-left: 0px;
        padding-right: 0px;
    }
    .ant-modal-content {
        padding-left: 16.66%;
        padding-right: 16.66%;
        padding-bottom: 16px;
    }
    .ant-modal-footer {
        border-top: none;
    }
    .deactiveBtn {
        margin-bottom: 14px;
        height: 40px;
        border-radius: 100px;
    }
    .deactive-title {
        text-align: center;
        font-family: IBM Plex Sans;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 28px;
        color: #000000;
        padding-top: 25px;
        padding-bottom: 25px;
        font-family: IBM Plex Sans;
    }
}