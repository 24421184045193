.advanced-search-side-bar {
  .collection-search-side-form{
    overflow-y: auto;
    overflow-x: hidden;
  }
  .scrollContent {
    max-height: 100vh;
    overflow-y: scroll;
    overflow-x: hidden;
    padding-right: 2px;
  }
  .heading-para {
    font-size: large;
    font-weight: 900;
    margin: 10px 10px 10px 10px;
  }
  .ant-card-body {
    padding: 0px;
  }
  .form-container {
    background: #f3f3f3;
    padding: 0px 6px 0px 7px;
    .provider-div {
      display: flex;
      flex-direction: column;
      row-gap: 5px;
    }

    .searchForm-card {
      border-radius: 4px;
      margin-top: 0px;
      padding: 0px 10px;

      .filters-div {
        label {
          width: 100%;
        }
      }
    }
    .row-spacing {
      display: flex;
      flex-direction: column;
      row-gap: 10px;
    }
  }

  .coll-search-header {
    padding: 5px 0px 5px 0px;
    background: #f3f3f3;
    display: flex;
    row-gap: 10px !important;
    margin: auto;
    .action-button {
      button{
        height: 30px;
        box-shadow: none;
      }
    }
    .clear-button {
      max-width: 230px !important;
      display: flex;
      justify-content: center;
      .primary{
        background: #ffffff !important;
        height: 30px;
        border: 1px solid #1C1C1C;
        color: #000000 !important;
        width: 93.05%;
        float: right;
        box-shadow: none;
      }
    }
    .primarySearch {
      width: 230px !important;
      display: flex;
      justify-content: center;
    }
  }
  
  .search-footer {
    display: flex;
    row-gap: 10px !important;
    flex-direction: column;
    margin: auto;

    .clear-button {
      width: 100%;
      margin: auto;
      max-width: 230px !important;
      display: flex;
      justify-content: center;
      .primary{
        background: #ffffff !important;
        height: 40px;
        border: 1px solid #1C1C1C;
        color: #000000 !important;
        width: 93.05%;
        float: right;
        box-shadow: none;
      }
    }
    .primarySearch {
      width: 100%;
      margin: auto;
      width: 230px !important;
      display: flex;
      justify-content: center;
    }
  }
  .linkButton {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }
  .category-div{
    .ant-select-selection-overflow{
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .ant-picker-panel-container{
    position: fixed;
    top: 0;
    left: 10px;
  }
}
.pb-10{
  padding-bottom: 10px !important;
}
.POcard{
  height:8vh;
  background-color:transparent;
}