.auto-rules {
  max-height: 75vh;
}

.commonSettings{
  .label{
    text-align: end;
    padding-right: 10px;
  }
  .ant-radio-group{
    width: 100%;
  }
  .spenDlimitInput{
    margin-bottom: 12px;
    input[type=number]::-webkit-inner-spin-button, 
    input[type=number]::-webkit-outer-spin-button {  
      opacity: 1;
    }
  }
  margin-bottom: 40px;
}
.ant-drawer-body {
  padding: 24px 0px 24px 24px !important;
}
.cart-title {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
  margin-bottom: 12px;
}
.radio-span {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #737373;
}
.details-row {
  padding: 12px;
  background: #ffffff;
  border: 1px solid #dedede;
  box-sizing: border-box;
  border-radius: 4px;
  margin-bottom: 12px;
}
.input-container {
  margin-bottom: 20px;
}
.radio-container {
  margin-bottom: 21px;
  .ant-radio-group {
    display: contents;
  }
}
.rules-row {
  margin-bottom: 12px;
}
.autoCart-input {
  height: 28px;
  width: 55px;
  .ant-input-number-handler-wrap {
    margin-right: 3px !important;
    width: 14px;
  }
}

.autoCart-maxcopies-input {
  height: 28px;
  width: 70px;
  padding-right: 10px!important;
  .ant-input-number-handler-wrap {
    margin-right: 3px !important;
    width: 14px;
  }
}

.ant-form-item-has-error .ant-input {
  background-image :none
}
.autoCart-switch {
  padding-top: 2px;
  height: 20px;
  width: 32px;
  .ant-switch-handle {
    top: 4px;
  }
}
.ant-switch-checked {
  background-color: #14ae23;
}
.ant-radio-inner::after {
  background-color: #1c1c1c;
}
.card-dropdown-withLabel {
  position: relative;
  .ant-select-selection-item{
      padding-top: 3px !important;
  }
  .ant-select-selection-overflow{
    padding-top: 3px !important;
  }
  label {
    position: absolute;
    top: -9px;
    left: 11px;
    background: white;
    z-index: 9;
    border: 4px solid #ffffff;
    color: #b1b1b1;
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 13px;
  }
}
.commonRowStyle{
padding-top: 15px;}

.discriptionStyle{
  font-size: 12px;
  font-family: 'TerminalDosisMedium',Helvetica,sans-serif;
  margin-top: 5px;
  color: #333;
  line-height: 1.42857;
}
.scrollContentAutoCart {
  overflow: auto;
  padding-right: 20px;
}
.singleAmount {
  padding-bottom: 8px;
}
.capReached {
  color: #FF0000;
}