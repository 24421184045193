.saved-search-list {
  padding-top: 12px;
}
.search-content {
  display: flex;
  border-bottom: 1px solid #b1b1b1;
  padding-bottom: 23px;
  margin-bottom: 24px;
}

.saved-list-title {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
  margin-bottom: 2px;
}
.saved-list-description {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #b1b1b1;
  margin-bottom: 2px;
}
.delete-text{
  font-family: IBM Plex Sans;
  font-weight: 700;
font-style: normal;
font-size: 14px;
line-height: 22px;
text-align: center;
color: #000000;
}

.delete-search{
  border: none;
  background: transparent;
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-top: 35px;
  color: red;
  text-decoration: none;
  cursor: pointer;
  max-width: fit-content;
}
.savedTitleStyle{
  text-align: center;
  padding-top:10PX ;
  font-family: IBM Plex Sans !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 18px !important;
  color: #737373 !important;
  display: flex;
  justify-content: center;
}
.savedTitleCountStyle{
  display: flex;
  align-items: center;
}

.saved-search-list .pageStyle {
 padding-top: 15px;
}

.savedsearchScroll {
  height: 62vh;
  @media screen and ( min-height: 850px ){
    height: 66vh;
    }
  @media screen and ( min-height: 950px ){
    height: 70vh;
    }
  @media screen and ( min-height: 1050px ){
    height: 73vh;
    }
}
.savedSearchDivider {
  margin-bottom: 0px;
}