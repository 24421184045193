.react-resizable {
  position: relative;
  background-clip: padding-box;
}

.CartglobalQtyInput{
  margin-left: 10px;
  .ant-input-number{
    background: #FFFFFF;
    border: 1px solid #DEDEDE;
    box-sizing: border-box;
    border-radius: 0 !important;
    max-width: 32px !important;
    max-height: 38px;
  }
}

.showCartglobalQtyInputTable{
  display: block !important;
  position: absolute;
  z-index: 999;
  left: 57px;
}

.notShowCartglobalQtyInputTable{
  display: none !important;
}

.notShowMenu{
  display: none !important;
}

.CartglobalQtyInputTable{
  position: absolute;
  z-index: 999;
  left: 4%;
  @media screen and ( max-width: 1200px ){
    left: 4.5% !important;
  }
  .ant-input-number{
    background: #FFFFFF;
    border: 1px solid #DEDEDE;
    box-sizing: border-box;
    border-radius: 0 !important;
    max-width: 32px !important;
    max-height: 38px !important;
    font-size: 1px !important;
  }
  .ant-input-number-input{
    margin-top: 5px;
    height: 29px;
  }
}

.react-resizable-handle {
  position: absolute;
  right: -5px;
  bottom: 0;
  z-index: 1;
  width: 10px;
  height: 100%;
  cursor: col-resize;
}

.activeYellowRow{
  background: #ffff00ab !important;
}
.activeYellowRow:hover{
  background: #ffff00ab !important;
}
.cart-detail-pagination {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  float: left;
  ul{
    padding-top: 5px;
    padding-bottom: 5px;
  }
}
.pagination-top-align{
  display: flex;
  justify-content: flex-start;
}
.cart-page-option {
  padding-top: 5px;
  padding-bottom: 5px;
  font-family: IBM Plex Sans;
}
.cartScreenTable .ant-checkbox-wrapper{
  padding: 20px 10px !important;
  margin: -20px -10px !important;
}

.checkboxClickableAreaWiden .ant-checkbox-wrapper{
  padding:20px;
  margin: -20px;
}
.checkboxClickableAreaWiden .ant-checkbox-inner {
  border: 1px solid #737373;
}

.scrollOnCartScreen{
  overflow-y: auto;
  padding-right: 14px;
}

.detailViewCheckbox{
  padding-left: 13% !important;
  @media screen and ( min-width: 1400px ){
    padding-left: 12% !important;
  }
  @media screen and ( min-width: 1505px ){
    padding-left: 11% !important;
  }
  @media screen and ( min-width: 1715px ){
    padding-left: 9% !important;
  }
}
.detailViewCheckbox .ant-checkbox-inner{
  border: 1px solid #737373;
}
.selected-book .ant-checkbox-inner{
  border: 1px solid #737373;
}
.hide_table_scroll .ant-table-body{
  overflow-y:none !important ;
}
.update_quan_margin{
  margin-left: 12px;
}
.pagination-container{
  display: flex;
  flex-direction: column;
  align-items: center;
}