.head-container {
  padding-right: 24px;
}
.edit-rules-disable {
  cursor: not-allowed !important;
}
.allRulesCard {
  margin-top: -20px;
}
.parentAutocartTab > .ant-tabs-nav {
  display: none;
}
.cart-label {
  text-align: center;
}
.cart-label-red {
  color: rgba(255, 0, 0, 0.937);
}
.cart-label-green {
  color: rgb(0, 128, 0);
}
.commonsettingSave {
  position: relative;
  button {
    position: absolute;
    width: 10%;
    height: 40px;
    right: 40px;
    top: -5px;
    z-index: 9;
  }
}
.page-title {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  color: #4f4949;
  margin-bottom: 0px;
}
.new-cart {
  height: 40px;
}
.cartList {
  padding-bottom: 15vh;
  max-height: 80vh;
}
.cart-tabs {
  .ant-tabs-tab-btn {
    color: #000000 !important;
    font-size: 14px;
    line-height: 18px;
    font-weight: 600;
    opacity: 0.5;
  }
  .ant-tabs-tab-active .ant-tabs-tab-btn {
    font-weight: 600;
    opacity: 1 !important;
  }
  .ant-tabs-ink-bar {
    background: #000000 !important;
  }
  .ant-tabs-content-holder {
    width: 100% !important;
  }
}
.cart-tabs-settings {
  width: 100%;
  .ant-tabs-tab-btn {
    color: #000000 !important;
    font-size: 14px;
    line-height: 18px;
    font-weight: 600;
    opacity: 0.5;
  }
  .ant-tabs-tab-active .ant-tabs-tab-btn {
    font-weight: 600;
    opacity: 1 !important;
  }
  .ant-tabs-ink-bar {
    background: #000000 !important;
  }
  .ant-tabs-content-holder {
    width: 100% !important;
  }
}

.cursor-pointer {
  cursor: pointer !important;
}
.cart-item {
  padding-left: 19px;
  padding-top: 19px;
  padding-right: 16px;
  padding-bottom: 18px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  border-radius: 10px;
  margin-bottom: 20px;
  .cart-details {
    padding-top: 14px;
    .cart-icon-container {
      text-align: center;
      display: flex;
      justify-content: space-between;
    }
  }
  .cart-title {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: #4f4949;
    cursor: pointer;
  }
  .auto-cart-title {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: #4f4949;
  }
  .cart-amount {
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #4f4949;
    padding-right: 12px;
  }
  .pl-5{
    padding-left: 5px;
  }
  .cart-edit-value {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    text-align: right;
    color: #b1b1b1;
  }
  .owned-icon {
    padding-right: 10px;
    padding-bottom: 5px;
  }
  .ellipsis-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .pr-15 {
    padding-right: 15px !important;
    @media screen and (max-width: 992px) {
      padding-right: 5px !important;
    }
  }
}
@media screen and (max-width: 992px) {
  .cart-item {
    padding-left: 7px;
    padding-right: 7px;
  }
  .font-11 {
    font-size: 11px !important;
  }
  .pr-15 {
    padding-right: 5px !important;
  }
}
@media screen and (min-width: 992px) and (max-width: 1200px) {
  .font-11 {
    font-size: 13px !important;
  }
}
.newCart-modal .ant-modal-footer {
  display: none;
}
.newCart-modal {
  .ant-modal-body {
    padding: 50px 90px;
  }
  .modal-title {
    justify-content: center;
    padding-bottom: 52px;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    display: flex;
    align-items: center;
    color: #1c1c1c;
  }
  .ant-checkbox-inner {
    padding-left: 2px;
    height: 30px !important;
    width: 30px !important;
  }
  .optional-text {
    padding-left: 15px;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    color: #333333;
  }
  .new-modal-input {
    height: 38px !important;
    border: 1px solid #dedede;
    box-sizing: border-box;
    border-radius: 19px;
    margin-bottom: 12px;
  }
  .new-cart-checkbox {
    display: flex;
    padding-top: 18px;
    padding-bottom: 52px;
    align-items: center;
    .ant-checkbox-inner::after {
      position: absolute;
      display: table;
      border: 1px solid #ffff;
      border-top: 0;
      border-left: 0;
      transform: rotate(42deg) scale(2) translate(-16%, -50%);
      opacity: 1;
      transition: all 0.1s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
      content: " ";
    }
  }
}
.delete-cart {
  .ant-modal-body {
    padding-top: 50px;
    padding-bottom: 22px;
  }
  .ant-modal-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-top: none;
    padding-bottom: 50px;
  }
  .delete-message {
    font-weight: normal;
    font-size: 20px;
    line-height: 28px;
    align-items: center;
    text-align: center;
    color: #000000;
  }
  .confirm-button {
    width: 240px;
    height: 40px;
  }
  .cancel-button {
    margin-top: 12px;
    width: 240px;
    height: 40px;
  }
}
.msg-slider-footer {
  padding-right: 24px;
}
.edit-rules {
  cursor: pointer;
  margin-right: 18px;
  margin-bottom: 0px;
}

.autoCarts-note {
  border-radius: 8px;
  align-items: center;
  padding-left: 16px;
  margin-bottom: 20px;
  p {
    color: #737373;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 0px;
  }
}
.ant-form-item {
  margin-bottom: 0px !important;
}
.cart-text {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #b1b1b1;
}
.spendLimitText {
  text-align: center;
}
.amountSpent {
  display: flex;
  justify-content: center;
}
.newLine {
  white-space: pre-line;
  font-family: IBM Plex Sans;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 120% !important;
  margin-bottom: 15px !important;
  color: #000000;
}
@media only screen and (max-width: 768px) {
  .newLine {
    white-space: pre-line;
    font-family: IBM Plex Sans;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 12px !important;
    line-height: 120% !important;
    margin-bottom: 15px !important;
    color: #000000;
  }
}
.messHed {
  font-family: IBM Plex Sans;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 18px !important;
  color: #000000;
}
.messLine {
  font-family: IBM Plex Sans;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 18px !important;
}
.alertBorder{
  border: 1px solid black;
  padding: 10px;
  margin-bottom: 20px;
}
.purLimBox {
  flex-direction: column;
}
.purLimRow {
  display: flex;
  justify-content: center;
  line-height: 18px !important;
}
.purLimRowHed {
  font-family: IBM Plex Sans;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 18px !important;
  color: #000000;
  padding-right: 10px;
}
.pb-10 {
  padding-bottom: 10px;
}
.pt-10 {
  padding-top: 10px;
}
.cartCancelBtn {
  margin-top: 25px;
  margin-left: 138px;
  border: none;
  background-color: rgb(0 0 0 / 0.01%);
  color: #ee0000;
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  cursor: pointer;
}
.cartRowGap {
  @media screen and (max-width: 767px) {
    row-gap: 5px !important;
  }
}

.shelfName {
  cursor: pointer;
}
.renderLoading {
  position: absolute;
  top: -5px;
  right: 2px;
}
.cartModelNameField {
  cursor: not-allowed !important;
}
.title-align {
  display: grid;
  align-items: center;
}

.paginationAlign {
  display: flex;
  justify-content: flex-end;
  padding-right: 50px;
}
.bottom-pagination-tag {
  width: 100%;
}
// .detailViewHeight{
//     height: 86vh !important;
//   @media screen and ( max-height: 980px ){
//     height: 85vh !important;
//   }
//   @media screen and ( max-height: 940px ){
//     height: 82vh !important;
//   }
//   @media screen and ( max-height: 880px ){
//     height: 82vh !important;
//   }
//   @media screen and ( max-height: 780px ){
//     height: 80vh !important;
//   }
//   @media screen and ( max-height: 720px ){
//     height: 78vh !important;
//   }
// }
.detailViewHeight {
  height: 100vh;
}
.cartDetailFooter {
  position: inherit !important;
  justify-content: end;
}
.hideDetailFooter {
  display: none;
}
.cart_title_padding {
  padding-top: 10px;
}
